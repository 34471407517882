import './NewsHome.scss';
import NewsList from '../NewsList/NewsList';
import axios from 'axios';
import {homePostsCache} from '../../utils/apicache';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

export default function NewsHome() {

    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        if (homePostsCache["homePosts"]) {
            // console.log("using results cache on homepage list");
            setResultsData(homePostsCache["homePosts"]);
        } else {
            // console.log("getting fresh posts list on homepage list");
            axios.get("https://pawwebsitearticleserver.azurewebsites.net/postslist/3")
            // axios.get("http://localhost:8000/postslist/3")
            .then(response => {
                // console.log("here is response.data: ", response.data);
                setResultsData(response.data);
                homePostsCache["homePosts"] = response.data;
            }, err => {
                console.log("**** error retrieving posts list:", err.response.status, err.response.statusText);
            })
        }
    }, [])   

    return (
        <section className="news-home">
            <h2 className="news-home__title"><Link className="news-home__title-link" to={`/news/${window.location.search}`}>Updates &amp; More</Link></h2>
            <NewsList resultsData={resultsData} />
        </section>
    )
}