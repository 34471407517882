import {useRef, useEffect, useState} from 'react';

// timerValue is how long it should count down for before redirecting to store page
export default function Countdown({timerValue = 3, appleUrl, androidUrl}) {
    const [timeLeft, setTimeLeft] = useState(timerValue);

    const intervalRef = useRef();

    // Decrements timer state by 1
    function decreaseNum() {
        setTimeLeft((prev) => prev - 1); 
        /* 
        NOTE ON ABOVE: Trying to pass just "timeLeft-1" as arg to setTimeLeft instead of above fxn will fail due to async issues 
        (react may batch setState calls, so no guarantee timeLeft will be prev value).
        Instead, setState can accept as arg a fxn instead of object. The first arg to this fxn is always the prev value of the state (So I could have called
        "prev" anything, as long as it's first arg)
        See 2nd ans here: https://stackoverflow.com/questions/54807454/what-is-prevstate-in-reactjs
        */
    }
    
    /* Below line must be in useEffect. Why? setInterval calls decreaseNum which calls setTimeLeft. Latter causes rerender
    which would run setInterval again if it's not in useEffect. So more & more setIntervals would run, making the decreaseNum go faster&faster, screwing up timer.
    By putting it in useEffect with [] dependency (ie, runs only on first mount), ensure setinterval timer only runs once.
    */
    // intervalRef.current = setInterval(decreaseNum, 1000);
    useEffect(() => {
        intervalRef.current = setInterval(decreaseNum, 1000);

        return () => clearInterval(intervalRef.current);
    }, [])

    // Detect when timer hits 0, then redirect user to store
    useEffect(() => {
        if (timeLeft === 0) {
            // end the interval fxn
            clearInterval(intervalRef.current); 
            
            // redirect to store page
            if (/iPhone|iPad/i.test(navigator.userAgent)) {
                window.location.href = appleUrl;
            } else {
                // NB: In parent components, ensured this component would only be used once if on iPhone/iPad/Android, so we assume this only triggered for Android users
                window.location.href = androidUrl;
            }
        }
    }, [timeLeft, appleUrl, androidUrl])

    return (
        <>{timeLeft}</>
    )
}