import {Link, useLocation} from 'react-router-dom';
import '../styles/styles.scss';


export default function Error404 () {
    let currSearch = useLocation().search;
    return (
        <div className="error404-container">
            <h1>404 Error: Sorry, page not found</h1>
            <h3><Link to={`/${currSearch}`}>Click here to return to the homepage</Link></h3>
        </div>
    )
}