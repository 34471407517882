export default function GetDate({theDate}) {
    // expects theDate is a string in YYYYMMDD format

    // First convert date to format that can be accepted as input to Date() to create a new date object
    // We use "/" as separator in the date as it's compatible with both Safari and Chrome implementations 
    // (the allowed format of input strings to Date() varies based on browser implementation)
    let rawDate = theDate.substring(0,4) + "/" + theDate.substring(4,6) + "/" + theDate.substring(6,8);

    // Now output it in the desired date format we want
    let formattedDate = new Date(rawDate).toLocaleDateString('en-US', {month: "long", day: "numeric", year:"numeric"})

    if (formattedDate === "Invalid Date") {
        formattedDate = ""
    }
    return (
        <>
        {formattedDate}
        </>
    )
}