import NewsletterFormContent from '../NewsletterFormContent';
import './Header.scss';
import PPALogo from '../../../assets/images/logo/logo-paw-patrol-academy-smaller_trans2.webp';
import PrizeBanners from '../../../assets/images/sweepstakes/prizes.png';
import { Link } from 'react-router-dom';

export default function Header({appleUrl,androidUrl,pageMode}) {

    return (
<>
<header className="header-landing">        

    <div className="header-landing__info">
        <h1 className="header-landing__info-title">PAW Patrol Academy<span className="header-landing__info-title--tm">&trade;</span></h1>
        <h2 className="header-landing__info-date">Yelp For Help Sweepstakes</h2>
        <img className="header-landing__info-logo" src={PPALogo} alt="PAW Patrol Academy logo" />
        <div className="header-landing__info-content">
            <div className="header-landing__info-subtitle-group">
                <div className="header-landing__info-subtitle-group-sticker">Win!</div>
                <img className="header-landing__info-subtitle-group-prizes" src={PrizeBanners} alt="Prizes include a $1000 grand prize, $500 gift cards, and $49.99 annual subscriptions" />
            </div>
            <iframe data-ot-ignore className="header-landing__info-video" width="800" height="450" src="https://www.youtube-nocookie.com/embed/buXYD4Yk_eI?rel=0&enablejsapi=1&modestbranding=1" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
    </div>
    {/* <!-- DOWNLOAD PREORDER STORE ICONS BAR (MOBILE/TABLET VERSION) --> */}
    <section className="download__container download__upper">
        <h2 className="download__title"><span className="download__title--top">What would you do with&nbsp;an&nbsp;extra&nbsp;$1000?</span><br />Share using #yelpforhelpsweepstakes</h2>
    </section>



    <div className="header-landing__form-outer-box">
    {/* <!-- UPPER FORM AREA --> */}

        <div className="header-landing__form">
            <img className="header-landing__form-logo" src={PPALogo} alt="PAW Patrol Academy logo" />
            
            <div className="header-landing__form-container">
                <h2 className="header-landing__form-title">Parents<br />Submit email to enter<br />No purchase necessary</h2>
                <NewsletterFormContent appleUrl={appleUrl} androidUrl={androidUrl} formLoc="upper" pageMode={pageMode} />
            </div>
        </div>

        <div className="legal-copy">
        NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. Ends 12:00pm ET 10/02/24. Open to 18+ and age of majority in US/DC and Canada (excl. Quebec). Subject to <Link to="/sweepstakes/rules" target="_blank" rel="noopener noreferrer">Official Rules</Link>. Total prize ARV: $2,500 USD. Free annual subscription requires subscription enrollment. Cancel before end of first year to avoid charges. Odds of winning depend on # of eligible entries recv'd. Void in Quebec and where prohibited. Sponsor: Spin Master Ltd., 225 King Street West Toronto, ON M5V 3M2.
        </div>
    </div>

</header>

{/* <!-- DOWNLOAD PREORDER STORE ICONS BAR (DESKTOP) --> */}
<section className="download__container download__lower">
<h2 className="download__title"><span className="download__title--top">What would you do with an extra $1000?</span><br />Share using #yelpforhelpsweepstakes</h2>
{/* <div className="download__icons-box">
    <a href="https://apps.apple.com/us/app/paw-patrol-academy/id6444813487" className="download__link store-link__apple" target="_blank"><img className="download__icon" src="./assets/images/logo/store_itunes.gif" alt="Pre-Order app on Apple iTunes store" /></a>
    <a href="https://play.google.com/store/apps/details?id=com.originatorkids.paw&pli=1" className="download__link store-link__android" target="_blank"><img className="download__icon" src="./assets/images/logo/store_google.gif" alt="Pre-Order app on Google Play store" /></a>
</div> */}
</section>

    </>

    )
}