import './Spotlight.scss';

import chaseImage from '../../assets/images/spotlight/chase.webp';
import spotlightBurst from '../../assets/images/spotlight/spotlightbkgd.webp';

export default function Spotlight({appleUrl, androidUrl}) {

    return (
        <section className="spotlight">
            <div className="spotlight__image-box">
                <img className="spotlight__image-bg" src={spotlightBurst} alt="Background starburst" />
                <img className="spotlight__image-fg" loading="lazy" src={chaseImage} alt="Chase from Paw Patrol Academy" />
            </div>
            <div className="spotlight__content">
                {/* <h3 className="spotlight__subtitle">Our Message To Parents</h3> */}
                <h2 className="spotlight__title">Our Message To Parents</h2>
                <p className="spotlight__copy">
                    PAW Patrol Academy has a special message for stressed-out parents of preschoolers. In addition to trusted, age-appropriate learning content for your little ones, 
                    we're offering  $2,500 in gift cards and prizes this fall to help you achieve your goals. 
                    Enter the Yelp for Help Sweepstakes for a chance to win the grand cash prize and offset rising family expenses like childcare.    
                </p>
                 <button className="download-button" onClick={() => window.scrollTo(0,0)}>Enter Now</button>
            </div>
        </section>
    )
}