import {useEffect} from 'react';
import MetaSEO from "../components/MetaSEO/MetaSEO";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Accordion from "../components/Accordion/index";

import '../styles/FAQ.scss';

const FAQ = ({appleUrl, androidUrl}) => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
  return (
    <>
      <MetaSEO
        title="FAQ Frequently Asked Questions | PAW Patrol Academy | Preschool Learning App | iOS & Android"
        canonical="https://www.pawpatrolacademy.com/faq"
        description="FAQ Frequently Asked Questions about the Paw Patrol Academy learning app for kids available on Apple iOS and Android devices.  Unleash all the fun and adventure of PAW Patrol in a world of learning that kids love and parents trust.  Featuring heroic missions, real, educational content and more."
        keywords="Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"
        type="webapp"
        name="Originator Kids | Spin Master"
        isFAQ={true}
      />
      <Header pageType="FAQ" appleUrl={appleUrl} androidUrl={androidUrl} />
      <div className="accordion__container">

        <div className="accordion__subtitle-box">
            <div className="accordion__subtitle-bg"></div>
            <div className="accordion__subtitle-fg"></div>
            <h2 className="accordion__subtitle-copy">General</h2>
        </div>

        <Accordion
          className="accordion accordion--general"
          openClassName="accordion__item--open"
        >
          <div
            data-header="What is PAW Patrol Academy?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              PAW Patrol Academy&trade; is an award-winning,
              preschool learning app for PAWsome preschool families to get their
              kids ready for the classroom. <br /><br />Designed for ages 2-5, with activities 
              that tackle subjects like spelling, numbers, shapes, music, coloring and more, PAW Patrol Academy invites your children inside Adventure Bay like never before, deepening their love of learning and accelerating their development and growth.
            </p>
          </div>
          <div
            data-header="Where is the app available?"
            className="accordion__item"
          >
            <p className="accordion__answer">
            The subscription app is free to download on iOS and Android devices globally. See your device's app store for geographic restrictions. The app is currently available in US English.
            </p>
          </div>
          <div data-header="How do I download it?" className="accordion__item">
            <p className="accordion__answer">
              On September 28th, you can visit the App Store or Google Play.
              Today, we recommend signing up for our mailing list at
              www.pawpatrolacademy.com , so you can have all the latest news and
              free trial information.
            </p>
          </div>
          <div data-header="Is the app free?" className="accordion__item">
            <p className="accordion__answer">
              The app is free to download and at launch, there will be free trial period for fans to enjoy full-access to the app.  After the free trial period ends, families would transition to a monthly or annual subscription plan to enjoy all the benefits of PAW Patrol Academy.  For the latest pricing details, visit your device's app store.  For more info and future promotional offers, parents can subscribe to the mailing list at pawpatrolacademy.com
            </p>
          </div>
          <div
            data-header="My kids are ages 2 and 5.  Will the app be fun for them?"
            className="accordion__item"
          >
            <p className="accordion__answer">Absolutely! The app was designed for preschoolers aged 2-5.</p>
          </div>
          <div
            data-header="How do I cancel my subscription?"
            className="accordion__item"
          >
            <p className="accordion__answer">
            To end your Subscription, please follow these instructions: 
            If you subscribed through the Apple App Store, please follow these instructions to cancel your subscription: <a href="https://support.apple.com/en-us/HT202039" target="_blank" rel="nofollow noreferrer">https://support.apple.com/en-us/HT202039</a>.  If you subscribed through the Google Play Store, follow these instructions instead: <a href="http://support.google.com/googleplay/answer/7018481" target="_blank" rel="nofollow noreferrer">http://support.google.com/googleplay/answer/7018481</a>.  We would love your feedback as to why you no longer wish to subscribe as we're always looking to improve. Please send any comments to <a href="mailto:support@pawpatrolacademy.com">support@pawpatrolacademy.com</a>
            </p>
          </div>
        </Accordion>
        <div className="accordion__subtitle-box">
            <div className="accordion__subtitle-bg"></div>
            <div className="accordion__subtitle-fg"></div>
            <h2 className="accordion__subtitle-copy">App Features & Compatibility</h2>
        </div>
        <Accordion
          className="accordion accordion--features"
          openClassName="accordion__item--open"
        >
          <div
            data-header="Are there in-app purchases?"
            className="accordion__item"
          >
            <p className="accordion__answer">No. The app is subscription-based.</p>
          </div>
          <div
            data-header="Can I use the app on a tablet?  What about a mobile device?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes.  The app is designed for iOS and Android devices.  Check your device's app store to ensure compatibility.
            </p>
          </div>
          <div
            data-header="Will it be available for Kindle?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Not at this time. The app will be available on iOS and Android
              devices at launch. Check your device's app store to ensure
              compatibility.
            </p>
          </div>
          <div
            data-header="How much does the subscription service cost?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              After the free trial period ends, families who want to enjoy the full PAW Patrol Academy experience would transition to a monthly or annual subscription plan.  For the latest pricing details in your region, please visit your device's app store.  For more info and future promotional offers, parents can subscribe to the mailing list at pawpatrolacademy.com.
            </p>
          </div>
          <div data-header="Is the app ad-free?" className="accordion__item">
            <p className="accordion__answer">Yes. </p>
          </div>
          <div
            data-header="Can the app be used offline?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes, the app can be used offline once it is downloaded, allowing
              children to enjoy learning activities even without wifi.
            </p>
          </div>
          <div
            data-header="Are there any social features?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              To ensure a safe and child-friendly learning environment, there
              are no social features in this app.
            </p>
          </div>
          <div
            data-header="Will the app be updated with new content?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Absolutely! We can't wait to launch, so we can then show you our
              plans for the future!
            </p>
          </div>
          <div
            data-header="Can I use the app while traveling internationally?"
            className="accordion__item"
          >
            <p className="accordion__answer">Si. Oui.  Ja. はい 。 The app is available in US English, but can be downloaded in most regions around the world.  Check your device's app store for details.</p>
          </div>
          <div
            data-header="Does the app come with audio cues for non-readers?"
            className="accordion__item"
          >
            <p className="accordion__answer">Yes</p>
          </div>
          <div
            data-header="Will I need to create an account for my child?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Not at this time. The app does not require creating an account to
              enjoy.
            </p>
          </div>
          <div
            data-header="Can I reset my child's progress?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes, you can reset your child's progress within the app settings
              if you wish to start fresh or have multiple children sharing the
              same account.
            </p>
          </div>
          <div
            data-header="Can I track my child's progress?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              With PAW Patrol Academy we stress the fun and joy of learning, but
              don't include any progress tracking.
            </p>
          </div>
          <div
            data-header="Can multiple children use the same account?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              There is no account set-up process, but there are tools that allow
              a second child to complete activities, even after a first child
              has completed them.
            </p>
          </div>
          <div
            data-header="Is there a limit to how long my child can use the app?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              As a learning app, PAW Patrol Academy can be enjoyed for as long
              as necessary without interruption. If this is a concern, we would
              encourage you to utilize the tools that may exist on your device.
            </p>
          </div>
          <div
            data-header="Can my child play on multiple devices?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes. The app can be enjoyed on multiple devices, but because there
              currently is not an account system, progress will not be saved
              between devices.
            </p>
          </div>
          <div
            data-header="Is PAW Patrol Academy set up for Family Sharing on iOS?  What about Family Link on Google Play?"
            className="accordion__item"
          >
            <p className="accordion__answer">
            As of March 2024, PAW Patrol Academy is set up for Family Sharing on iOS.  We are still exploring the ability to use Family Link. 
            </p>
          </div>

        </Accordion>
        
        <div className="accordion__subtitle-box">
            <div className="accordion__subtitle-bg"></div>
            <div className="accordion__subtitle-fg"></div>
            <h2 className="accordion__subtitle-copy">Content &amp; Learning</h2>
        </div>
        <Accordion
          className="accordion accordion--content"
          openClassName="accordion__item--open"
        >
          <div
            data-header="What types of learning will be included?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              For more details on individual activities, we strongly encourage you to sign up to our mailing list or download the app for a free trial.
            </p>
          </div>
          <div
            data-header="Will there be different levels of difficulty?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes. Certain activities are designed to increase with difficulty,
              while others are not. Across the different activities and
              missions, there is truly something for every child at every level
              to enjoy. In addition, many of the activities are designed to be
              repeatable, so kids can learn to master concepts.
            </p>
          </div>
          <div
            data-header="Can my child listen to music or watch videos on the app?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes! Your child will be dancing up a storm at Skye's Dance Party!
              And each vocabulary word unlocked through play will show a video
              clip from actual PAW Patrol episodes!
            </p>
          </div>
          <div
            data-header="Can my child learn to spell and write?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              Yes! PAW Patrol Academy includes activities focused on key skills
              like letter sounds, handwriting basics, numbers, shapes and more.
            </p>
          </div>
          <div
            data-header="Are there rewards for completing activities?"
            className="accordion__item"
          >
            <p className="accordion__answer">
              As your child plays through learning content, clips from actual
              PAW Patrol episodes are played as rewards. We know through
              exptensive testing with preschoolers, that this is exactly the
              kind of reward they love!
            </p>
          </div>
        </Accordion>
        
        <div className="accordion__subtitle-box">
            <div className="accordion__subtitle-bg"></div>
            <div className="accordion__subtitle-fg"></div>
            <h2 className="accordion__subtitle-copy">Safety &amp; Privacy</h2>
        </div>
        <Accordion
          className="accordion accordion--safety"
          openClassName="accordion__item--open"
        >
          <div data-header="What about safety?" className="accordion__item">
            <p className="accordion__answer">
              We value your child's safety and privacy and are committed to
              providing a secure learning space. The app adheres to strict
              privacy standards and as an app for kids, we do not collect any
              personally identifiable information. Kids will not be interacting
              with any other users in the app, providing another layer of safety
              and protection.
            </p>
          </div>
          <div
            data-header="Does the app comply with COPPA regulations?"
            className="accordion__item"
          >
            <p className="accordion__answer">Yes</p>
          </div>
        </Accordion>
      </div>
      <Footer pageType="FAQ" />
    </>
  );
};

export default FAQ;
