// import {useState} from 'react';
// import axios from 'axios';

import './Newsletter.scss';
import NewsletterFormContent from './NewsletterFormContent';
import formImage from "../../assets/images/misc/bottom-banner-paw.webp";
// import loadingImage from '../../assets/images/misc/loading.gif';
// import storeIconApple from "../../assets/images/logo/store_itunes.gif";
// import storeIconGoogle from "../../assets/images/logo/store_google.gif";

// import Countdown from '../Countdown/Countdown'; // counts down & redirects

// allows us to pass a reference to an element in this component back up to parent (used for jump link in header)
// Parent passes down a ref setup via useRef, then we assign it to reference an element within this component. So the parent can then reference the subcomponent.
import {forwardRef} from 'react'; 

// export default function Newsletter({appleUrl, androidUrl}) {
    const Newsletter = forwardRef((props,ref) => {

    return (
        <section className="bottom-form">
            <div className="header__form bottom-form__form">
            <div className="header__form-container">
                <h2 className="header__form-title" ref={ref}>{(props.pageMode && props.pageMode === "landing-page") ? <>Sign up to receive<br />extended free trial</> : "Sign up to learn more"}</h2>

                <NewsletterFormContent appleUrl={props.appleUrl} androidUrl={props.androidUrl} pageMode={props.pageMode} />
                </div>
            </div>
            <img className="bottom-form__image" src={formImage} alt="PAW Patrol Academy Artwork" loading="lazy" />
        </section>
    )
});

export default Newsletter;