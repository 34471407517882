import './AsFeaturedIn.scss';
import mashableImg from '../../assets/images/asfeaturedin/mashable.webp';
import techcrunchImg from '../../assets/images/asfeaturedin/techcrunch.webp';
import tinybeansImg from '../../assets/images/asfeaturedin/tinybeans.webp';
import romperImg from '../../assets/images/asfeaturedin/romper.webp';

export default function AsFeaturedIn() {

    return (
        <section className="as-featured">
            <h2 className="as-featured__title">As Featured In</h2>
            <div className="as-featured__image-box">
                <img className="as-featured__image" src={mashableImg} alt="PAW Patrol Academy was featured in Mashable" />
                <img className="as-featured__image" src={techcrunchImg} alt="PAW Patrol Academy was featured in Tech Crunch" />
                <img className="as-featured__image" src={romperImg} alt="PAW Patrol Academy was featured in Romper" />
                <img className="as-featured__image" src={tinybeansImg} alt="PAW Patrol Academy was featured in TinyBeans" />
            </div>
        </section>
    )

}