
// Consistent way to get name from category ID.  While proper method would be API call, it is slower and unnecessary here since we will rarely add or change categories
export default function CategoryName({catId}){
    let categoryName;
    switch (catId) {
        case 3:
            categoryName="Blog";
            break;
        case 4:
            categoryName="Updates";
            break;
        default:
            categoryName="";
    }

    return (
        <>{categoryName}</>
    )
}