import {useEffect, useState} from 'react';
import axios from 'axios';
import MetaSEO from '../MetaSEO/MetaSEO';
import { newsListCache } from '../../utils/apicache';

import PrintableListItem from './PrintableListItem';
import './PrintablesLanding.scss';

export default function PrintablesLanding(appleUrl, androidUrl) {
  
    const [resultsData, setResultsData] = useState(null);
    const [stickyPosts, setStickyPosts] = useState(null);
    const [regularPosts, setRegularPosts] = useState(null);

    useEffect(() => {
        window.scrollTo(0,0);
        
        if (newsListCache["allPrintables"]) {
            // see if already have a cached response.  If so, use it.
            setResultsData(newsListCache["allPrintables"]);
        } else {
            axios.get("https://pawwebsitearticleserver.azurewebsites.net/printableslist/")
            .then(response => {
                // console.log("here is response.data: ", response.data);
                setResultsData(response.data);
                newsListCache["allPrintables"] = response.data;
            }, err => {
                console.log("**** error retrieving printables list:", err.response.status, err.response.statusText);
            })
        }
      }, [])
  
      useEffect(() => {
  
        // split data into featured & non-featured lists
        // Don't forget that there is WP cache, API server cache, and browser cache that all need to be busted to see change
        if (resultsData) {
  
          let tempSticky = resultsData.filter((item) => item.menu_order !== 0).sort((a,b) => b.menu_order - a.menu_order);
          if (tempSticky) setStickyPosts(tempSticky);
          
          let tempRegular = resultsData.filter((item) => parseInt(item.menu_order) === 0);
          if (tempRegular) setRegularPosts(tempRegular);
        }
  
      }, [resultsData])

      
      return (
        <>
            <MetaSEO
            title={`Printables Coloring Pages | PAW Patrol Academy | Preschool Learning App | iOS & Android`}
            canonical={`https://www.pawpatrolacademy.com/printables`}
            description={`Exclusive coloring sheets from the Paw Patrol Academy - Download, print and color`}
            keywords="Coloring pages, Colouring pages, Printables, Printable, Sheets, Paw Patrol, Fun activity, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout"
            type="webapp"
            name="Originator Kids | Spin Master"
            isFAQ={false}
            />
            <section className="printables-landing">
                <section className="printables-landing__header-box">
                    <h1 className="printables-landing__title">PAWSome Printables</h1>
                    <h2 className="printables-landing__subtitle">Download Some Screen-Free Fun For Your Pawsome Preschoolers</h2>
                </section>

                <ul className="printables-list">
                    
                {(stickyPosts) ? 
                    stickyPosts.map((result, index) => 
                        // (<Link key={index} className="printables-list__item-link" to={`/news/post/${result.id}${window.location.search}`}>
                        <PrintableListItem result={result} isSticky={true} appleUrl={appleUrl} androidUrl={androidUrl} key={index} />
                        // </Link>)
                    )
                : ""}
                    {(regularPosts) ? 
                    regularPosts.map((result, index) => 
                        <PrintableListItem result={result} isSticky={false} appleUrl={appleUrl} androidUrl={androidUrl} key={index} />
                    )
                : ""}
                </ul>

            </section>
        </>
      )
}