import {useRef, useLayoutEffect, useState, useEffect} from 'react';
import videoPlaceholder from '../../assets/images/video/video-placeholder-paw2.webp';
import YouTube from 'react-youtube';
import {gsap} from 'gsap';
import { CustomEase } from "gsap/CustomEase"
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';
import './Video.scss';

// required to use the CustomEase plugin from gsap
gsap.registerPlugin(CustomEase, ScrollTrigger);

export default function VideoTest() {

    const [pauseAnim, setPauseAnim] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    function handleVidClick() {
      // We defer loading the video until user clicks.  This helps greatly with perf, esp largest contentful paint & blocking time
      setShowVideo(true);
      if (window.OnetrustActiveGroups.includes("C0004")) { // If targeting cookies enabled, then we will use YT player which is setup to handle animation pause. If not, then don't pause animations.
        handleVideoPlay(); // make paws pause
      }
    }

    // const videoRef = useRef();
    const pawGroupLeft = useRef();
    const pawGroupRight = useRef();

    // Creating ctx vars outside of UseLayoutEffect so that can access anims to pause them via external actions (eg, play vid or btn press)
    // Need to use a ref when done this way, so need to use .current to access its contents
    // If ctx was only used within useLayoutEff, then could just use a regular variable instead of ref (wouldn't need .current)
    let ctx1 = useRef(); 
    let ctx2 = useRef();
    useLayoutEffect(() => {
        // This set makes it look like paws are going up sides in alternating fashion, as if one huge big set
        // left paw group
        ctx1.current = gsap.context(() => {
            /* subtleties with stagger:
            * > A -ve val for 'each' == apply in reverse order; relieves me from needing to rearrange order of divs
            * > Putting yoyo WITHIN stagger object means each indiv paw fades out/reverse irrespective of others, properly 
            * creating effect of trail fading out. If yoyo was outside stagger obj, then fade out applied to entire set & would look
            * like paws are fading out in reverse order, as if trail reversing itself. You don't want that.
            * > Repeat:1 within stagger obj allows yoyo to work. The yoyo is the 'repeat' step. Without repeat, yoyo never triggered.
            * Notice it's only repeat once: So fade paw in, then fade out. Then the other repeat on the whole set restarts entire process.
            */ 
            // Nice fade in from bottom one by one, then fade out from bottom one by one
            gsap.from(".video__paw", {scrollTrigger: {trigger: ".video__paw", markers: false, start: "bottom center", once: true}, opacity: 0, duration: 1.4, stagger: {each:-1, repeat: 1, yoyo:true}, repeat: -1, repeatDelay: 0.7});            
            
            // Fast fade in and scale big to normal step with a squish bounce at end
            // gsap.from(".video__paw", {opacity: 0, duration: 1, stagger: {each:-1}, repeat: -1, repeatDelay: 2});            
            // gsap.from(".video__paw", {scale: 1.5, ease: CustomEase.create("custom", "M0,0 C0.14,0 0.242,0.438 0.272,0.561 0.313,0.728 0.354,0.963 0.362,1 0.462,0.886 0.575,0.981 0.582,0.998 0.684,0.998 1,1 1,1 "), duration: 1, stagger: {each: -1}, repeat: -1, repeatDelay: 2});            
            // gsap.from(".video__paw", {scale: 1.5, ease:"bounce.out", duration: 1, stagger: {each: -1}});            
        }, pawGroupLeft); // Ref limits scopes of context so can use class names instead of refs
        
        // console.log("ctx log: ", ctx1.getTweens()[0].pause()); // non-ref ctx1
        
        // ctx1.getTweens()[0].pause();

        // right paw group
        ctx2.current = gsap.context(() => {
            // Nice fade in from bottom, then each fades out from bottom
            gsap.from(".video__paw", {scrollTrigger: {trigger: ".video__paw", markers: false, start: "bottom center", once: true}, opacity: 0, duration: 1.4, stagger: {each:-1, repeat: 1, yoyo:true}, delay: 0.7, repeat: -1, repeatDelay: 0.7});            
        }, pawGroupRight);
        return () => {
            ctx1.current.revert();
            ctx2.current.revert();
        }
    }, [])

    // Pauses/Plays paw prints around the video player
    useEffect(() => {
        if (pauseAnim) {
            // revert() instead of pause() would reset paws to be all visible & static. To restart, would use restart() instead of resume()
            ctx1.current.getTweens()[0].pause(); 
            ctx2.current.getTweens()[0].pause(); 
            // ctx1.current.getTweens()[0].revert(); 
            // ctx2.current.getTweens()[0].revert();
        } else {
            if (ctx1.current.getTweens()[0].paused() || ctx2.current.getTweens()[0].paused()) {
                ctx1.current.getTweens()[0].resume();
                ctx2.current.getTweens()[0].resume();
            }
            // ctx1.current.getTweens()[0].restart(true); // true means respect the original start delay u set
            // ctx2.current.getTweens()[0].restart(true);
        }
    }, [pauseAnim])

    function handleVideoPlay() {
        setPauseAnim(true);
    }
    function handleVideoPause() {
        setPauseAnim(false);
    }

    // settings for YT player
    const opts = {
        height: '450',
        width: '800',
        host: 'https://www.youtube-nocookie.com',
        'data-ot-ignore': 'true',
        playerVars: {
          autoplay: 1,
          rel: 0,
          enablejsapi: 1,
        },
    };

    return (
        <>
        {/* <button onClick={() => {ctx1.current.getTweens()[0].pause(); console.log("clicked");}}>Stop animation</button> */}

        {/* <section className="video" ref={videoRef}> */}  
        <section className="video">
            
        
            <div className="video__paw-group--left" ref={pawGroupLeft}>
                <div className="video__paw video__paw1"></div>  
                <div className="video__paw video__paw2"></div>
                <div className="video__paw video__paw3"></div>
            </div>
            <div className="video__paw-group--right" ref={pawGroupRight}>
                <div className="video__paw video__paw4"></div>    
                <div className="video__paw video__paw5"></div>
                <div className="video__paw video__paw6"></div>
            </div>
            {/* <div className="video__paw video__paw1"></div>
            <div className="video__paw video__paw2"></div>
            <div className="video__paw video__paw3"></div>
            <div className="video__paw video__paw4"></div>
            <div className="video__paw video__paw5"></div>
            <div className="video__paw video__paw6"></div> */}

            {/* <YoutubeEmbed embedId="e89r2xDEC7M" /> */}

            {/* {(!showVideo) && 
            <img className="video__show-button-image" src={videoPlaceholder} loading="lazy" alt="Play PAW Patrol Academy Trailer Video" width="853" height="480" onClick={handleVidClick} /> 
            }
            {(showVideo) && 
            // <p className="video__notification">If you cannot see the video player, then please enable cookies. 
            //     <br />Click the cookie icon in the bottom left of the screen to allow cookies, then reload the page.</p>
                <YouTube {...`data-ot-ignore`} dataOtIgnore="true" videoId="e89r2xDEC7M" opts={opts} iframeClassName="video__player" title="YouTube video player" onPlay={handleVideoPlay} onPause={handleVideoPause} onEnd={handleVideoPause} />
            } */}
            {(!showVideo) ? 
            <img className="video__show-button-image" src={videoPlaceholder} loading="lazy" alt="Play PAW Patrol Academy Trailer Video" width="853" height="480" onClick={handleVidClick} /> 
            : <>
                {(window.OnetrustActiveGroups.includes("C0004")) ? 
                    <YouTube {...`data-ot-ignore`} dataOtIgnore="true" videoId="e89r2xDEC7M" opts={opts} iframeClassName="video__player" title="YouTube video player" onPlay={handleVideoPlay} onPause={handleVideoPause} onEnd={handleVideoPause} />
                : 
                <iframe className="video__player" src="https://player.vimeo.com/video/985957027?dnt=true&amp;autoplay=true&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Paw Patrol Academy | Official Trailer | Educational Mobile App Game"></iframe>
                // <p className="video__notification"><strong>Please enable cookies to watch&nbsp;the&nbsp;video.</strong>
                //    <br />Click the cookie icon in the bottom left of the screen, allow all cookies, then reload the page.</p> 
                }
               </>
            }
            
            
            
        </section>
    </>       
    )
}