import NewsList from '../NewsList/NewsList';
import MetaSEO from '../MetaSEO/MetaSEO';
import {useEffect, useState} from 'react';
import { newsListCache } from '../../utils/apicache';
import axios from 'axios';
import './NewsLanding.scss';

// Lists posts on the news landing page.  Currently just grabs all posts.  Will eventually need to update to be able to paginate & show categories
const NewsLanding = () => {

    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        window.scrollTo(0,0);
        
        if (newsListCache["allPosts"]) {
            // see if already have a cached response.  If so, use it.
            setResultsData(newsListCache["allPosts"]);
        } else {
            axios.get("https://pawwebsitearticleserver.azurewebsites.net/postslist/32")
            // axios.get("http://localhost:8000/postslist/31")
            .then(response => {
                // console.log("here is response.data: ", response.data);
                setResultsData(response.data);
                newsListCache["allPosts"] = response.data;
            }, err => {
                console.log("**** error retrieving posts list:", err.response.status, err.response.statusText);
            })
        }
    }, [])   
        
    return (
        <>
            <MetaSEO
            title={`News, Updates, Tips, Education | PAW Patrol Academy | Preschool Learning App | iOS & Android`}
            canonical={`https://www.pawpatrolacademy.com/news`}
            description={`The latest updates, news, and tips from the Paw Patrol Academy App including best practices for preschool learning`}
            keywords="Preschool Learning and Education Articles, Tips, Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"
            type="webapp"
            name="Originator Kids | Spin Master"
            isFAQ={false}
            />
            <section className="news-landing">
                <h1 className="news-landing__title">News and Updates</h1>
                <NewsList resultsData={resultsData} />
            </section>
        </>
        
    )
}

export default NewsLanding;