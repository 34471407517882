
// Consistent way to get name from category ID.  While proper method would be API call, it is slower and unnecessary here since we will rarely add or change categories
export default function PrintableCategoryName({catId}){
    const categoryNames = {
        "5": "Holiday Cards",
        "9": "Coloring Sheets",
        "7": "Fine Motor Crafts"
    }
    
    return (
        <>{categoryNames[catId]}</>
    )
}