import NewsletterFormContent from '../../Newsletter/NewsletterFormContent';
import './Header.scss';
import PPALogo from '../../../assets/images/logo/logo-paw-patrol-academy-smaller_trans2.webp';

export default function Header({appleUrl,androidUrl,pageMode}) {

    return (
<>
<header className="header-landing">        

<div className="header-landing__info">
    <h1 className="header-landing__info-title">PAW Patrol Academy<span className="header-landing__info-title--tm">&trade;</span></h1>
    <h2 className="header-landing__info-date">Special Event Offer</h2>
    <img className="header-landing__info-logo" src={PPALogo} alt="PAW Patrol Academy logo" />
    <div className="header-landing__info-content">
        <div className="header-landing__info-subtitle-group">
            <p className="header-landing__info-subtitle">Heroic missions. </p>
            <p className="header-landing__info-subtitle">REAL educational content.</p>
        </div>
        <iframe data-ot-ignore className="header-landing__info-video" width="800" height="450" src="https://www.youtube-nocookie.com/embed/e89r2xDEC7M?rel=0&enablejsapi=1&modestbranding=1" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <p className="header-landing__info-subtitle header-landing__info-subtitle--last">It's learning unleashed!</p>
    </div>
</div>
{/* <!-- DOWNLOAD PREORDER STORE ICONS BAR (MOBILE/TABLET VERSION) --> */}
<section className="download__container download__upper">
    <h2 className="download__title">Thanks For Joining&nbsp;Us</h2>
    {/* <div className="download__icons-box">
        <a href="https://apps.apple.com/us/app/paw-patrol-academy/id6444813487" className="download__link store-link__apple"><img className="download__icon" src="./assets/images/logo/store_itunes.gif" alt="Download app on Apple iTunes store" /></a>
        <a href="https://play.google.com/store/apps/details?id=com.originatorkids.paw&pli=1" className="download__link store-link__android"><img className="download__icon" src="./assets/images/logo/store_google.gif" alt="Download app on Google Play store" /></a>
    </div> */}
</section>



{/* <!-- UPPER FORM AREA --> */}
<div className="header-landing__form">
    <img className="header-landing__form-logo" src={PPALogo} alt="PAW Patrol Academy logo" />
    
    <div className="header-landing__form-container">
        <h2 className="header-landing__form-title">Sign up to receive <br />extended free trial</h2>
        <NewsletterFormContent appleUrl={appleUrl} androidUrl={androidUrl} formLoc="upper" pageMode={pageMode} />
    </div>
</div>
</header>

{/* <!-- DOWNLOAD PREORDER STORE ICONS BAR (DESKTOP) --> */}
<section className="download__container download__lower">
<h2 className="download__title">Thanks For Joining Us</h2>
{/* <div className="download__icons-box">
    <a href="https://apps.apple.com/us/app/paw-patrol-academy/id6444813487" className="download__link store-link__apple" target="_blank"><img className="download__icon" src="./assets/images/logo/store_itunes.gif" alt="Pre-Order app on Apple iTunes store" /></a>
    <a href="https://play.google.com/store/apps/details?id=com.originatorkids.paw&pli=1" className="download__link store-link__android" target="_blank"><img className="download__icon" src="./assets/images/logo/store_google.gif" alt="Pre-Order app on Google Play store" /></a>
</div> */}
</section>

    </>

    )
}