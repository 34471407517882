// Before hardcode 

import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import '../styles/FAQ.scss';

const Printables = ({mode, appleUrl, androidUrl}) => {

    useEffect(() => {
      window.scrollTo(0,0);
    }, []);

    return (
    <>

      <Header pageType="news" appleUrl={appleUrl} androidUrl={androidUrl} />
      
        <Outlet />

      <Footer pageType="news" />
    </>
  );
};

export default Printables;

