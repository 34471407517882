import './Testimonials.scss';

import Slider from "react-slick";
// Import css files used by slick slider carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Carousel images:
import testimonialHeart from "../../assets/images/testimonials/testimonial-heart.webp";


// push event to help track carousel swipes
function handleSwipe(e) {
    window.dataLayer.push({'event': 'testimonial-carousel-swipe'});
}

function Testimonials(props){

    var settings = {
        onSwipe: handleSwipe,
        dots: false,
        autoplay: {delay: 6000, pauseOnMouseEnter: true,},
        autoplaySpeed: 7000,
        infinite: true,
        vertical: false,
        centerMode: false,
        mobileFirst: true,
        lazyLoad: 'ondemand',
        speed: 1500,
        slidesToShow: 1, // 3,
        slidesToScroll: 1, // must be 1 when center mode is on
        swipeToSlide: true, /* if enabled, swipe no longer smoothly scrolls multiple items like slidetoScroll, but tradeoff is do not see empty slide pop in either */
        initialSlide: 0,
        responsive: [
            {  
                breakpoint: 8000,
                settings: {
                    slidesToShow: 3, // cannot go higher than # of slides
                    dots: true,
                }
            },
            // The slick slider breakpoints are max. So breakpoint 3000 means do X when viewport is <= 3000px
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 867,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 523, 
                settings: {
                    slidesToShow: 1,
                    // effect: 'cards',
                    // fade: true,
                    dots: true,
                    speed: 1000,
                }
            }
        ]
      };
    
    return (

        <section className="testimonial" id="testimonial">
            <h2 className="testimonial__title">
                What Grown-Ups Are Saying
            </h2>

            


            <div className="testimonial__carousel-container">
            <div className="testimonial__carousel">
                <Slider {...settings}>

                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            Finally!! We’ve been searching for a good app that our kids will actually retain information from and we have found it. My kids are delighted to learn from their favorite pups and feel like part of the Paw Patrol.
                            </div>
                            <div className="testimonial__text-name">
                            Camille G.<br />Mom of two preschoolers
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            Fun and educational app that has something for everyone
                            </div>
                            <div className="testimonial__text-name">
                            Dr. Gummer's Good Play Guide<br />Child development expert
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            Unique among Paw Patrol apps, it nurtures emotional resilience, self-expression, and creativity.
                            </div>
                            <div className="testimonial__text-name">
                            Aline B.<br />Expert app reviewer
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            I got it for my grandson, and he loves it - building letters, hearing sounds plus missions!
                            </div>
                            <div className="testimonial__text-name">
                                Dianna P.<br />Grandmother of 3-year-old
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            With its focus on letter writing, vocabulary, art, and music, the app supports various aspects of early childhood development, making it a worthwhile addition to your child’s learning toolkit.
                            </div>
                            <div className="testimonial__text-name">
                                Erika C.<br />Independent child language development expert
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            My daughter was able to recall what she had learned using the app and apply it to a real-world scenario. We were impressed!
                            </div>
                            <div className="testimonial__text-name">
                                Hilda<br />Mother of 2-year-old
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            My kindergartner loves this app! When I need a few minutes to focus on a task, I don't feel guilty while she's playing. She's having a great time learning!
                            </div>
                            <div className="testimonial__text-name">
                                Ernesto B.<br />Father of 6-year-old
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>
                    <div className="testimonial__box">
                        <div className="testimonial__box-bg">
                            &nbsp;
                        </div>
                        <div className="testimonial__box-fg">
                            <img src={testimonialHeart} className="testimonial__icon" alt="heart icon" />
                            <div className="testimonial__text-body">
                            I wasn't expecting this to be such a great game! It feels like you're part of the show. Truly one of the greatest apps for children I have found!
                            </div>
                            <div className="testimonial__text-name">
                                Ricardo D.<br />Dad of 7-year-old
                            </div>
                        </div>
                        <div className="testimonial__box-corner-quote">
                        {`\u201C`}
                        </div>
                    </div>

                
            </Slider>
            </div>

            </div>
        </section>


    )
}

export default Testimonials;