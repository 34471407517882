// Before hardcode 

import {useEffect, useState} from 'react';
import {useParams, Outlet} from 'react-router-dom';
import axios from 'axios';
import MetaSEO from "../components/MetaSEO/MetaSEO";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NewsArticle from "../components/NewsArticle/NewsArticle";

import '../styles/FAQ.scss';

const News = ({mode, appleUrl, androidUrl}) => {

    const {articleId} = useParams();
    // const [articleContent, setArticleContent] = useState(null);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    // Get the article
    // useEffect(() => {
    //   let idNumber = parseInt(articleId);
    //   if (Number.isInteger(idNumber) && idNumber >= 0) {
    //     axios.get(`https://paw-articlesc.azurewebsites.net/wp-json/wp/v2/posts/${articleId}`)
    //     .then(response => {
    //       setArticleContent(response.data)
    //     })
    //   } else {
    //     console.log("INVALID ARTICLE ID!");
    //   }
    // }, [articleId])

    // console.log("response data: ", articleContent);
  return (
    <>
      {/* <MetaSEO
        title="Article | PAW Patrol Academy | Preschool Learning App | iOS & Android"
        canonical="https://www.pawpatrolacademy.com/faq"
        description="FAQ Frequently Asked Questions about the Paw Patrol Academy learning app for kids available on Apple iOS and Android devices.  Unleash all the fun and adventure of PAW Patrol in a world of learning that kids love and parents trust.  Featuring heroic missions, real, educational content and more."
        keywords="Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"
        type="webapp"
        name="Originator Kids | Spin Master"
        isFAQ={true}
      /> */}
      <Header pageType="news" appleUrl={appleUrl} androidUrl={androidUrl} />
      

      <Outlet />
      {/* <NewsArticle articleId={articleId} /> */}

      <Footer pageType="news" />
    </>
  );
};

export default News;

