import { Helmet } from 'react-helmet-async';



const MetaSEO = ({ title, canonical, description, keywords, type, name, isFAQ, noindex }) => {

    
  return (
    <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonical} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:image" content="https://www.pawpatrolacademy.com/images/pawpatrolacademy_social_1200x630.jpg" />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {(noindex === true) ? <meta name="robots" content="noindex" /> : ""}
        <script type="application/ld+json">
            {(!isFAQ) ? 
            
            JSON.stringify({
                "@context": "https://schema.org",
                "@graph": [
                {
                "@type": "MobileApplication",
                "name": "PAW Patrol Academy",
                "publisher": {
                    "@type": "Organization",
                    "name": ["Originator", "Spin Master"]
                },
                "operatingSystem": ["iOS","ANDROID"],
                "downloadUrl": [
                    "https://apps.apple.com/us/app/paw-patrol-academy/id6444813487",
                    "https://play.google.com/store/apps/details?id=com.originatorkids.paw&pli=1"
                ],
                "applicationCategory": ["GameApplication","EducationalApplication","EntertainmentApplication"],
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.4",
                    "bestRating": "5",
                    "ratingCount": "4541"
                },
                "offers": {
                    "@type": "Offer",
                    "price": "0.00",
                    "priceCurrency": "USD"
                }
                },
                {
                "@type": "VideoGame",
                "name": "PAW Patrol Academy",
                "publisher": {
                    "@type": "Organization",
                    "name": ["Originator", "Spin Master"]
                },
                "operatingSystem": ["iOS","ANDROID"],
                "downloadUrl": [
                    "https://apps.apple.com/us/app/paw-patrol-academy/id6444813487",
                    "https://play.google.com/store/apps/details?id=com.originatorkids.paw&pli=1"
                ],
                "applicationCategory": ["GameApplication","EducationalApplication","EntertainmentApplication"],
                "offers": {
                    "@type": "Offer",
                    "price": "0.00",
                    "priceCurrency": "USD"
                }
                }
                ]
            })
            
            : 
            JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                    "@type": "Question",
                    "name": "What is PAW Patrol Academy?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Launching September 28th, PAW Patrol AcademyTM  is a brand-new, preschool learning app for PAWsome preschool families to get their kids ready for the classroom. Designed for ages 2-5, with activities that tackle subjects like spelling, numbers, shapes, music, coloring and more, PAW Patrol Academy invites your children inside Adventure Bay like never before, deepening their love of learning and accelerating their development and growth.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Where will it be launching?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>The subscription app is free to download on iOS and Android devices globally.  See your device's app store for geographic restrictions.  The app will be available in 2023 in US English.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "How do I download it?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>On September 28th, you can visit the App Store or Google Play.  Today, we recommend signing up for our mailing list at www.pawpatrolacademy.com , so you can have all the latest news and free trial information.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Is the app free?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>The app is free to download and at launch, there will be free trial period for fans to enjoy full-access to the app.  After the free trial period ends, families would transition to a monthly or annual subscription plan to enjoy all the benefits of PAW Patrol Academy.  For the latest pricing details, visit your device's app store.  For more info and future promotional offers, parents can subscribe to the mailing list at pawpatrolacademy.com</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "My kids are ages 2 and 5.  Will the app be fun for them?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Absolutely! The app was designed for preschoolers aged 2-5.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Are there in-app purchases?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>No.  The app is subscription-based.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can I use the app on a tablet?  What about a mobile device?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes.  The app is designed for iOS and Android devices.  Check your device's app store to ensure compatibility.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Will it be available for Kindle?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Not at this time.  The app will be available on iOS and Android devices at launch.  Check your device's app store to ensure compatibility.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "How much does the subscription service cost?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>After the free trial period ends, families who want to enjoy the full PAW Patrol Academy experience would transition to a monthly or annual subscription plan.  For the latest pricing details in your region, please visit your device's app store.  For more info and future promotional offers, parents can subscribe to the mailing list at pawpatrolacademy.com.  </p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Is the app ad-free?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes.  </p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can the app be used offline?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes, the app can be used offline once it is downloaded, allowing children to enjoy learning activities even without wifi.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Are there any social features?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>To ensure a safe and child-friendly learning environment, there are no social features in this app.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Is the app available in multiple languages?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>At launch, the app will be available in US English. We will be expanding the app to include more languages in 2024.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Will the app be updated with new content?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Absolutely!  We can't wait to launch, so we can then show you our plans for the future!</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can I use the app while traveling internationally?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Si. Oui.  Ja. はい 。 The app is available in US English, but can be downloaded in most regions around the world.  Check your device's app store for details.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Does the app come with audio cues for non-readers?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Will I need to create an account for my child?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Not at this time.  The app does not require creating an account to enjoy.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can I reset my child's progress?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes, you can reset your child's progress within the app settings if you wish to start fresh or have multiple children sharing the same account.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can I track my child's progress?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>With PAW Patrol Academy we stress the fun and joy of learning, but don't include any progress tracking. </p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can multiple children use the same account?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>There is no account set-up process, but there are tools that allow a second child to complete activities, even after a first child has completed them.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Is there a limit to how long my child can use the app?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>As a learning app, PAW Patrol Academy can be enjoyed for as long as necessary without interruption.  If this is a concern, we would encourage you to utilize the tools that may exist on your device.  </p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can my child play on multiple devices?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes. The app can be enjoyed on multiple devices, but because there currently is not an account system, progress will not be saved between devices.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "What types of learning will be included?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>For more details on individual activities, we strongly encourage you to sign up to our mailing list or download the app for a free trial.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Will there be different levels of difficulty?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes.  Certain activities are designed to increase with difficulty, while others are not.  Across the different activities and missions, there is truly something for every child at every level to enjoy.  In addition, many of the activities are designed to be repeatable, so kids can learn to master concepts.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can my child listen to music or watch videos on the app?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes! Your child will be dancing up a storm at Skye's Dance Party!  And each vocabulary word unlocked through play will show a video clip from actual PAW Patrol episodes!</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Can my child learn to spell and write?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes! PAW Patrol Academy includes activities focused on key skills like letter sounds, handwriting basics, numbers, shapes and more.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Are there rewards for completing activities?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>As your child plays through learning content, clips from actual PAW Patrol episodes are played as rewards.  We know through exptensive testing with preschoolers, that this is exactly the kind of reward they love!</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "What about safety?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>We value your child's safety and privacy and are committed to providing a secure learning space.  The app adheres to strict privacy standards and as an app for kids, we do not collect any personally identifiable information.  Kids will not be interacting with any other users in the app, providing another layer of safety and protection.</p>"
                    }
                    },
                    {
                    "@type": "Question",
                    "name": "Does the app comply with COPPA regulations?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "<p>Yes</p>"
                    }
                    }
                    
                ]    
            })
            }
        </script>
        <script type="text/javascript" src="/js/accessibility.js" defer></script>
    
    </Helmet>
  );
};

MetaSEO.defaultProps = {
    isFAQ: false,
};

export default MetaSEO;