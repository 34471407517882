
import MetaSEO from '../components/MetaSEO/MetaSEO';
import Header from '../components/LandingPage/Header/Header';
// import Video from '../components/Video/Video';
// import Features from '../components/Features/Features';
import Activities from '../components/Activities/Actitivites';
import Spotlight from '../components/Spotlight/Spotlight';
// import Offer from '../components/Offer/Offer';
import Testimonials from '../components/Testimonials/Testimonials';
// import NewsHome from '../components/NewsHome/NewsHome';
// import Pricing from '../components/Pricing/Pricing';
import AsFeaturedIn from '../components/AsFeaturedIn/AsFeaturedIn';
import Newsletter from '../components/Newsletter/Newsletter';
import Footer from '../components/Footer/Footer';


import {useRef} from 'react';

import '../styles/styles.scss';

export default function LandingPage({appleUrl, androidUrl}) {

    // Used to enable the anchor link in the header component
    const activitiesRef = useRef();
    const newsletterRef = useRef();

    return (
        <>
        <MetaSEO 
            title="PAW Patrol Academy | Preschool Learning App | iOS & Android"
            canonical="https://www.pawpatrolacademy.com/"
            description="Unleash all the fun and adventure of PAW Patrol in a world of learning that kids love and parents trust.  Featuring heroic missions, real, educational content and more."
            keywords="Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"    
            type="webapp"
            name="Originator Kids | Spin Master"
        />
        <div className="site-container">
            <Header appleUrl={appleUrl} androidUrl={androidUrl} pageMode="landing-page"/>
            {/* <Video /> */}
            {/* <Features /> */}

            <Activities ref={activitiesRef} pageMode="landing-page" />
            <Spotlight appleUrl={appleUrl} androidUrl={androidUrl}/>
            <Testimonials />
            {/* <Offer appleUrl={appleUrl} androidUrl={androidUrl} /> */}
            {/* <Pricing appleUrl={appleUrl} androidUrl={androidUrl} /> */}
            {/* <NewsHome /> */}
            <AsFeaturedIn />
            <Newsletter appleUrl={appleUrl} androidUrl={androidUrl} ref={newsletterRef} pageMode="landing-page" />
            <Footer />
        </div>
        </>

    )
}