import MetaSEO from "../MetaSEO/MetaSEO";
import "./Rules.scss";

export default function Terms() {
  return (
    <>
      <MetaSEO
        title="Terms for Yelp For Help Sweepstakes | PAW Patrol Academy | Preschool Learning App | iOS & Android"
        canonical="https://www.pawpatrolacademy.com/sweepstakes/rules"
        description="T&C for the PAW Patrol Academy Yelp for Help sweepstakes"
        keywords="Contest, Sweepstakes, Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"
        type="webapp"
      />
      <div className="site-container rules__container">
        <div class="entry-content wp-block-post-content has-global-padding is-layout-constrained wp-block-post-content-is-layout-constrained">
          <h1>SWEEPSTAKES Terms & Conditions</h1>

          <p>
            <strong>
              No purchase or payment of any kind is necessary to enter or win. A
              purchase will not increase your chance of winning.&nbsp; Void in
              Quebec and where prohibited by law.&nbsp;&nbsp;
            </strong>
          </p>

          <p>
            <strong>
              IMPORTANT: PLEASE READ THESE OFFICIAL RULES, WHICH ARE A CONTRACT,
              CAREFULLY BEFORE ENTERING. WITHOUT LIMITATION, THIS CONTRACT
              INCLUDES AGREEMENT TO ARBITRATE DISPUTES, INDEMNITIES TO THE
              SPONSOR FROM YOU AND A LIMITATION OF YOUR RIGHTS AND REMEDIES. BY
              PARTICIPATING, YOU AGREE TO BE BOUND BY THESE OFFICIAL RULES AND
              REPRESENT THAT YOU SATISFY ALL OF THE ELIGIBILITY
              REQUIREMENTS.&nbsp;
            </strong>
          </p>

          <ol>
            <li>
              <strong>OVERVIEW: </strong>&nbsp;The Paw Patrol Yelp For Help
              Sweepstakes (“<strong>Sweepstakes</strong>”), is sponsored by Spin
              Master Ltd. (“<strong>Sponsor</strong>”). The Sweepstakes begins
              <strong>
                <em> </em>
              </strong>
              at 12:00:00 pm Eastern Time (“<strong>ET</strong>”) on 9/4/24, and
              ends at 12:00:00 pm ET on 10/02/2024 (“
              <strong>Sweepstakes Period</strong>”). The Sweepstakes is governed
              by these official rules (“<strong>Official Rules</strong>”) and is
              void in Quebec and where prohibited by law.&nbsp;&nbsp;
            </li>
          </ol>

          <ol start="2">
            <li>
              <strong>ELIGIBILITY</strong>: This Sweepstakes is open to legal
              residents of the 50 United States and DC and Canada (excluding
              Quebec) who are at least eighteen (18) years of age and the age of
              majority in their jurisdiction of residence at the time of entry.
              Employees, personnel, officers, directors, members, managers,
              agents, and representatives of Sponsor or any other entities
              participating in the design, promotion, marketing, administration,
              or fulfillment of this Sweepstakes, or any of their respective
              corporate partners, parent companies, divisions, subsidiaries,
              affiliates, successors in interest, and advertising, promotion,
              and public relations agencies (collectively, the “
              <strong>Released Parties</strong>”),<strong> </strong>and the
              immediate families (defined as any spouse, partner, parent, legal
              guardian, sibling, child, grandparent, or grandchild, regardless
              of where they reside) and members of the same household (whether
              legally related or not) of each such individual, are ineligible to
              participate or win the prize in this Sweepstakes. Participation in
              this Sweepstakes constitutes entrant’s full and unconditional
              agreement to and acceptance of these Official Rules and the
              decisions of Sponsor.&nbsp;
            </li>
          </ol>

          <ol start="3">
            <li>
              <strong>HOW TO ENTER:</strong> During the Sweepstakes Period,
              individuals who meet the eligibility requirements (each, an “
              <strong>Entrant</strong>”) can access the entry form by visiting{" "}
              <a href="https://www.pawpatrolacademy.com/sweepstakes">
                Https://www.pawpatrolacademy.com/sweepstakes
              </a>{" "}
              or through the parents’ section of the Paw Patrol Academy app
              (either, the “<strong>Sweepstakes Site</strong>”) and complete the
              Sweepstakes entry form with all required information to earn one
              (1) entry into the Sweepstakes. For purposes of the Sweepstakes,
              an entry is received only when the Sweepstakes Site’s servers
              record the submission of an Entrant’s complete, submitted entry
              form (the “<strong>Entry</strong>”). Entries must be submitted and
              received by Sponsor during the Sweepstakes Period, through the
              Sweepstakes Site, and in strict accordance with the instructions
              and restrictions in these Official Rules and on the Sweepstakes
              Site.&nbsp;
            </li>
          </ol>

          <p>
            Limit one (1) entry per person. Proof of submitting an entry (such
            as, without limitation, a printed, saved or copied automated receipt
            confirming submission or a “thanks for entering” screen or message)
            does not constitute proof of actually receiving of an entry for
            purposes of the Sweepstakes. Entries may not be acknowledged. Those
            who do not provide the required responses or entry information in
            their entry or abide by these Official Rules and other instructions
            of Sponsor and its representatives may, in Sponsor’s sole
            discretion, be disqualified and the associated entry void. Attempts
            made by an Entrant to submit entries in excess of any limit or
            otherwise in violation of these Official Rules by using multiple or
            false contact information or otherwise may be disqualified.
            Purported entries that are forged, altered, incomplete, lost, late,
            misdirected, mutilated, illegitimate, unintelligible, garbled, or
            generated by a macro, bot, or other automated means will not be
            accepted and will be void. Entries or participation made on an
            Entrant’s behalf by any other individual, made by any entity or
            group, or originating at any website or online service other than
            the Sweepstakes Site, including, without limitation, through
            commercial promotion subscription notification or entering services,
            will be declared invalid and disqualified for the Sweepstakes. No
            Released Party will have any responsibility or liability for any
            dispute regarding any Entrant. In the event that any dispute
            regarding an entry cannot be resolved to Sponsor’s satisfaction, the
            entry will be void.&nbsp;
          </p>

          <ol start="4">
            <li>
              <strong>WINNER SELECTION/NOTIFICATION:</strong> At the end of the
              Sweepstakes Period, thirteen (13) potential winner(s) will be
              selected in a random drawing from among all eligible entries
              received. The drawing will be held within approximately two
              business days after the end of the Sweepstakes Period. The
              Entrant(s) selected as a potential winner for the Sweepstakes
              (each a “<strong>Winner</strong>”) will be notified by email
              within three (3) business days of the drawing, using the email
              address provided in their Entry.&nbsp; In the event a Winner
              selected to win the Sweepstakes (a) is deemed ineligible, (b)
              fails to comply with these Official Rules, (c) does not respond to
              the Winner notification and/or complete any required Winner
              Documents (defined below) in accordance with Sponsor’s
              instructions and by the stated deadline, or (d) is not available
              to claim the Prize, the Prize may be awarded to an alternate
              winner, as determined by Sponsor within its sole and absolute
              discretion, even if the Winner has been publicly announced.
            </li>
          </ol>

          <p>
            Potential prize winners who reside in a Canadian province or
            territory (exclusive of Quebec) must correctly answer a mathematical
            skill-testing question to be administered by Sponsor, without aid or
            assistance in order to be confirmed a Winner.&nbsp; Failure to
            correctly answer the skill-testing question will result in
            forfeiture of the prize and an alternative Winner may, at Sponsor’s
            discretion, be selected.&nbsp;
          </p>

          <p>
            If any prize, prize notification, or Sweepstakes-related
            communication is rejected, faulty, or undeliverable; if a Winner
            does not respond according to the notification’s or Sponsor’s
            instructions; or if a Winner fails to comply with these Official
            Rules, the prize may, in Sponsor’s sole discretion, be forfeited and
            an alternate winner may be selected (time permitting) based on the
            original method of winner selection or other method that is fair in
            Sponsor’s sole discretion. Sponsor is not obligated to pursue more
            than three (3) alternate winners for any prize for any reason, in
            which case such prize may go unawarded. Sponsor reserves the right
            to modify the notification procedures and applicable deadlines for
            responding in connection with the selection of any alternate
            potential winner, if any. If a prize is legitimately claimed, it
            will be awarded.<strong> </strong>Upon prize forfeiture or inability
            to use a prize or portion thereof, no compensation will be given and
            Sponsor will have no responsibility or liability to the Winner. To
            claim a prize, a Winner must follow the directions in these Official
            Rules, the winner notification and as otherwise communicated by
            Sponsor. Winner may, in Sponsor’s sole discretion, be required to
            complete, sign, notarize (if applicable), and return, without
            alteration and in the form presented by Sponsor, a
            declaration/affidavit of eligibility and liability/publicity release
            and tax forms (as applicable) (collectively, the “
            <strong>Winner Documents</strong>”) according to Sponsor’s
            instructions within five (5) business days from when Sponsor sends
            the Winner Documents or other time frame as specified by Sponsor or
            its representative. The Winner Documents, if required, are each
            subject to verification by Sponsor. Failure to comply with these
            requirements, Sponsor’s or its representative’s instructions, or
            these Official Rules may, in Sponsor’s sole discretion, result in
            disqualification from the Sweepstakes and forfeiture of any prize
            potentially won.<em> </em>Decisions of the Sponsor are final on all
            matters relating to the Sweepstakes, including interpretation of
            these Official Rules, determining the winners, and awarding of the
            prizes.&nbsp;
          </p>

          <ol start="5">
            <li>
              <strong>
                PRIZE, ODDS &amp; APPROXIMATE RETAIL VALUE (“ARV”)
              </strong>
              :
            </li>
          </ol>

          <p>
            One (1) Grand Prize winner will receive $1000 USD awarded in the
            form of an American Express gift card sent via email to the winner.
          </p>

          <p>
            Two (2) First Prize winners will each receive $500 USD awarded in
            the form of an American Express gift card sent via email to the
            winner.&nbsp;
          </p>

          <p>
            Ten (1) Runner-Up winners will each receive a one-year subscription
            to Paw Patrol Academy. ARV: $50.
          </p>

          <p>
            Each annual subscription winner will be provided a code via email
            with instructions on how to redeem the code at either the App Store
            or Google Play Store. Redemption of the free one-year subscription
            requires sign-up for an auto-renewing subscription to Paw Patrol
            Academy using a valid payment method through the Apple App Store or
            Google Play Store. To avoid charges, turn off automatic renewals for
            your subscription at least twenty-four hours before the end of your
            first year. If you subscribed through the Apple App Store, please
            follow these instructions to turn off automatic renewals:
            https://support.apple.com/en-us/HT202039.
          </p>

          <p>
            If you subscribed through the Google Play Store, follow these
            instructions instead: support.google.com/googleplay/answer/7018481.
          </p>

          <p>Total ARV of all prizes: $2500 USD.</p>

          <p>
            Odds of winning depend on the number of eligible entries received.
            All prize details not specifically stated in these Official Rules
            will be determined by Sponsor in its sole discretion. Gift cards
            subject to the terms and conditions set forth by issuer. Any taxes
            (federal, state, provincial or local) arising from receipt of the
            prize, as well as any expenses in excess of the prize amount are the
            sole responsibility of Winner. Sponsor is not responsible for and
            will not replace any lost, mutilated, or stolen prize, or any prize
            that is undeliverable or does not reach a Winner because of
            incorrect or changed contact information. If Winner does not accept
            or use the entire prize, the unaccepted or unused part of the prize
            will be forfeited and Sponsor will have no further obligation with
            respect to that prize or portion of the prize. Sponsor is not
            responsible for any inability of any Winner to accept or use any
            prize (or portion thereof) for any reason. No transfers or prize
            substitutions will be made, except at Sponsor’s sole discretion.
            Sponsor reserves the right to substitute the stated prize or portion
            thereof with another prize or portion thereof of equal or greater
            value for any reason. No more than the stated prize will be awarded.
            Winner waives the right to assert as a cost of winning any prize,
            any and all costs of verification and redemption and any liability
            and publicity which might arise from claiming or seeking to claim a
            prize. Prizes will be fulfilled within four to six weeks following
            Winner verification.
          </p>

          <ol start="6">
            <li>
              <strong>
                LIMITATION OF LIABILITY: To the fullest extent permitted under
                applicable law, each individual entering the Sweepstakes agrees
                to release, hold harmless, and indemnify each of the Released
                Parties from and against any liability whatsoever for injuries
                or damages of any kind sustained in connection with
                participating in the Sweepstakes, including use of the
                Sweepstakes Site, the acceptance, use, misuse, or awarding of
                the prize or while preparing for, participating in, and/or
                traveling to or from any prize or Sweepstakes-related activity
                including, without limitation, any injury, damage, loss, death
                or accident to/of person or property. The limitations set forth
                in this section will not limit or exclude the Released Parties’
                liability for personal injury or tangible property damage caused
                by the released parties, or for the Released Parties’ gross
                negligence, fraud, or intentional, willful, malicious, or
                reckless misconduct.
              </strong>
            </li>
          </ol>

          <p>
            <strong>
              To the fullest extent permitted by applicable law, Winner agrees
              that the prize is provided as-is without any warranty,
              representation, or guarantee, express or implied, in fact or in
              law, whether now known or hereinafter enacted, relative to the use
              or enjoyment of the prize, including, without limitation, its
              quality, merchantability, or fitness for a particular purpose.
            </strong>
          </p>

          <p>
            <strong>
              Each Entrant understands and agrees that all rights under Section
              1542 of the Civil Code of California and any similar law of any
              other eligible jurisdiction are hereby expressly waived by them
              against the Released Parties.&nbsp; Section 1542 reads as follows:
            </strong>
          </p>

          <p>
            <strong>
              “A general release does not extend to claims which the creditor or
              releasing party does not know or suspect to exist in his or her
              favor at the time of executing the release and that, if known by
              him or her, would have materially affected his or her settlement
              with the debtor or released party.”
            </strong>
          </p>

          <ol start="7">
            <li>
              <strong>ADDITIONAL DISCLAIMERS:</strong> Released Parties are not
              responsible and/or liable for any of the following, whether caused
              by a Released Party, the Entrant, or by human error: entries that
              are submitted by illegitimate means (such as, without limitation,
              by an automated computer program) or entries in excess of any
              stated limit; any lost, late, incomplete, illegible,
              unintelligible, garbled, mutilated, or misdirected entries, email,
              mail, or Sweepstakes-related correspondence or materials or
              postage-due mail; any error, omission, interruption, defect or
              delay in transmission or communication; viruses or technical or
              mechanical malfunctions; interrupted or unavailable cable or
              satellite systems; errors, typos, or misprints in these Official
              Rules, in any Sweepstakes-related advertisements, or other
              materials; failures of electronic equipment, computer hardware, or
              software; lost or unavailable network connections, or failed,
              incorrect, incomplete, inaccurate, garbled or delayed electronic
              communications or entry information. Released Parties are not
              responsible for electronic communications that are undeliverable
              or do not reach Entrant as a result of any form of active or
              passive filtering of any kind, or insufficient space in an
              Entrant’s email account to receive email messages. Released
              Parties are not responsible, and may disqualify an Entrant, if an
              email address or other contact information does not work or is
              changed without prior written notice to Sponsor. Without limiting
              any other provision in these Official Rules, Released Parties are
              not responsible or liable to any Winner or any person claiming
              through such Winner for failure to supply the prize or any part
              thereof in the event that any of the Sweepstakes activities or
              Released Parties’ operations or activities are affected, as
              determined by Sponsor in its sole discretion, including, without
              limitation, by reason of any acts of God, any action, regulation,
              order or request by any governmental or quasi-governmental entity
              (whether or not the action, regulations, order or request proves
              to be invalid), equipment failure, threatened terrorist acts,
              terrorist acts, air raid, blackout, act of public enemy,
              earthquake, war (declared or undeclared), fire, flood, epidemic,
              explosion, unusually severe weather, hurricane, embargo, labor
              dispute or strike (whether legal or illegal), labor or material
              shortage, transportation interruption of any kind, work slow-down,
              civil disturbance, insurrection, riot, or any law, rule,
              regulation, order or other action adopted or taken by any
              governmental, federal, state, provincial or local government
              authority, or any other cause, whether or not specifically
              mentioned above.
            </li>
          </ol>

          <ol start="8">
            <li>
              <strong>GENERAL RULES:</strong> By entering the Sweepstakes
              (except where prohibited by law), each Entrant grants the Released
              Parties the irrevocable, sublicensable, absolute right and
              permission to use, publish, post or display their name,
              photograph, likeness, voice, prize information, biographical
              information, any quotes attributable to them, any other indicia of
              persona, and any information contained in or submitted as part of
              their entry (regardless of whether altered, changed, modified,
              edited, used alone, or used with other material in the Released
              Parties’ sole discretion) for advertising, trade, promotional and
              publicity purposes without further obligation or compensation of
              any kind to them, anywhere, in any medium now known or hereafter
              discovered or devised (including, without limitation, on the
              Internet), world-wide, without any limitation of time, and without
              notice, review, or approval by Winner, and each Entrant releases
              all Released Parties from any and all liability related thereto.
              Nothing contained in these Official Rules obligates Sponsor to
              make use of any of the rights granted in these Official Rules and
              Winner waives any right to inspect or approve any such use.&nbsp;
            </li>
          </ol>

          <p>
            Sponsor’s decisions will be final in all matters relating to this
            Sweepstakes, including interpretation of these Official Rules,
            determination of any Winner, and awarding of any prize. All
            participants, as a condition of entry in the Sweepstakes, agree to
            be bound by these Official Rules and the decisions of Sponsor.
            Failure to comply with these Official Rules may result in
            disqualification from the Sweepstakes. Participants further agree to
            not damage or cause interruption of the Sweepstakes and/or prevent
            others from using the Sweepstakes Site. Sponsor reserves the right
            to restrict or void online entries or participation from any IP
            address if any suspicious entry and/or participation is detected.
            Sponsor reserves the right, in its sole discretion, to void entries
            of any Entrants who Sponsor believes have attempted to tamper with
            or impair the administration, security, fairness, or proper play of
            the Sweepstakes. Sponsor’s failure to or decision not to enforce any
            provision in these Official Rules will not constitute a waiver of
            that or any other provision. In the event there is an alleged or
            actual ambiguity, discrepancy, or inconsistency between disclosures
            or other statements contained in any Sweepstakes-related materials
            and/or these Official Rules (including any alleged discrepancy in
            these Official Rules), it will be resolved by Sponsor in its sole
            discretion. Entrants waive any right to claim ambiguity in any
            Sweepstakes materials, or these Official Rules. If Sponsor
            determines, at any time and in its sole discretion, that a winner or
            potential winner is disqualified, ineligible, in violation of these
            Official Rules, or engaging in behavior that Sponsor deems
            obnoxious, inappropriate, threatening, illegal or that is intended
            to annoy, abuse, threaten or harass any other person, Sponsor
            reserves the right to disqualify that winner or potential winner,
            even if the disqualified winner or potential winner may have been
            notified or displayed or announced anywhere. The invalidity or
            unenforceability of any provision of these Official Rules will not
            affect the validity or enforceability of any other provision. In the
            event that any provision is determined to be invalid or otherwise
            unenforceable or illegal, these Official Rules will otherwise remain
            in effect and will be construed in accordance with their collective
            terms as if the invalid or illegal provision were not contained
            herein. If the Sweepstakes is not capable of running as planned for
            any reason, Sponsor reserves the right, in its sole discretion, to
            cancel, modify or suspend the Sweepstakes and award the prize from
            eligible entries received prior to cancellation, modification, or
            suspension or as otherwise deemed fair and appropriate by Sponsor.
            If any person supplies false information, obtains entries by
            fraudulent means, or is otherwise determined to be in violation of
            these Official Rules in an attempt to obtain any prize, Sponsor may
            disqualify that person and seek damages from him or her and that
            person may be prosecuted to the full extent of the law. CAUTION: ANY
            ATTEMPT TO DAMAGE ANY ONLINE SERVICE OR WEBSITE OR TO UNDERMINE THE
            LEGITIMATE OPERATION OF A SWEEPSTAKES VIOLATES CRIMINAL AND CIVIL
            LAWS. IF SUCH AN ATTEMPT IS MADE, SPONSOR MAY DISQUALIFY ANY
            PARTICIPANT MAKING SUCH ATTEMPT AND MAY SEEK DAMAGES TO THE FULLEST
            EXTENT PERMITTED BY LAW.
          </p>

          <ol start="9">
            <li>
              <strong>GOVERNING LAW/DISPUTES/ARBITRATION:</strong>&nbsp;
            </li>
          </ol>

          <p>
            <strong>If you participate from within Canada:</strong> You agree
            that these Official Rules, your participation in the Sweepstakes and
            use of the Sweepstakes Site will be governed, construed, and
            interpreted under the laws of Ontario, Canada, without reference or
            giving effect to its conflicts of law principles or rules that would
            cause the application of any other jurisdiction’s laws. Any legal
            actions, suits, or proceedings related to this Sweepstakes (whether
            for breach of contract, tortious conduct, or otherwise) will be
            brought exclusively in the provincial or federal courts located in
            or having jurisdiction over Toronto, Ontario, Canada and each
            Entrant irrevocably accepts, submits, and consents to the exclusive
            jurisdiction and venue of these courts with respect to any legal
            actions, suits, or proceedings arising out of or related to this
            Sweepstakes. Unless prohibited by applicable law, all Entrants waive
            any and all objections to jurisdiction and venue in these courts and
            hereby submit to the jurisdiction of those courts.&nbsp;
          </p>

          <p>
            <strong>If you participate from within the United States</strong>:
            You agree that these Official Rules, your participation in the
            Sweepstakes and use of the Sweepstakes Site are governed by the laws
            of the State of California. Both you and Sponsor waive the right to
            a trial by jury and the right to bring or resolve any dispute as a
            class, consolidated, representative, collective, or private attorney
            general action. Notwithstanding any provision in the JAMS (defined
            below) rules to the contrary, the arbitrator shall not have the
            authority or any jurisdiction to hear the arbitration as a class,
            consolidated, representative, collective, or private attorney
            general action or to consolidate, join, or otherwise combine the
            claims of different persons into one proceeding.&nbsp;
          </p>

          <p>
            The parties each agree to finally settle all disputes only through
            arbitration; provided, however, the Sponsor shall be entitled to
            seek injunctive or equitable relief in the state and federal courts
            located in or having jurisdiction over San Francisco, CA and any
            other court with jurisdiction over the parties.&nbsp; In
            arbitration, there is no judge or jury and review is limited.&nbsp;
            The arbitrator’s decision and award is final and binding, with
            limited exceptions, and judgment on the award may be entered in any
            court with jurisdiction.&nbsp; The parties agree that, except as set
            forth above, any claim, suit, action or proceeding arising out of or
            relating to this Sweepstakes will be resolved solely by binding
            arbitration before a sole arbitrator under the streamlined
            arbitration rules &amp; procedures of JAMS Inc. (“JAMS”) or any
            successor to JAMS.&nbsp; In the event JAMS is unwilling or unable to
            set a hearing date within fourteen (14) days of the filing of a
            “demand for arbitration,” then either party can elect to have the
            arbitration administered by another mutually agreeable arbitration
            administration service who will hear the case.&nbsp; If an in-person
            hearing is required, then it will take place near Sponsor’s offices
            in San Francisco, CA or – if required by the JAMS’ Rules – in or
            near participant’s county/state of residence.&nbsp; The federal or
            state law that applies to these Official Rules will also apply
            during the arbitration.&nbsp; Disputes will be arbitrated only on an
            individual basis and will not be consolidated with any other
            proceedings that involve any claims or controversy of another party,
            including any class actions or class arbitrations; provided,
            however, if for any reason any court or arbitrator holds that this
            restriction is unconscionable or unenforceable, then the agreement
            to arbitrate does not apply and the dispute must be brought in a
            court of competent jurisdiction in San Francisco, CA. Sponsor agrees
            to pay the administrative and arbitrator’s fees in order to conduct
            the arbitration (but specifically excluding any travel or other
            costs of Entrant to attend the arbitration hearing). Either party
            may, notwithstanding this provision, bring qualifying claims in
            small claims court.&nbsp; In no event will you seek or be entitled
            to rescission, injunctive or other equitable relief or to enjoin or
            restrain the operation or exploitation of the Sweepstakes.
          </p>

          <p>
            To increase the efficiency of administration and resolution of
            arbitrations, you and the Released Parties agree that, in the event
            that there are 100 or more individual demands for arbitration of a
            similar nature filed against Sponsor or another Released Party by or
            with the assistance of the same law firm, group of law firms, or
            organization(s) within a 30-day period (or otherwise in close
            proximity), the arbitration administration service (1) will
            administer the arbitration demands in batches of 100 per batch
            (plus, to the extent there are less than 100 demandants left over
            after the batching described above, a final batch consisting of the
            remaining parties); (2) appoint one arbitrator for each batch; and
            (3) provide for the resolution of each batch as a single
            consolidated arbitration with one set of case management fees and
            arbitrator compensation due per batch, one procedural calendar, one
            hearing (if any) in a place to be determined by the arbitrator, and
            one final award (“<strong>Batch Arbitration</strong>”). You and the
            Released Parties agree (1) to work with the arbitration
            administrator/service in good faith to facilitate the resolution of
            disputes on a Batch Arbitration basis and (2) that requests for
            arbitration are of a “similar nature” if they arise out of the same
            event, agreement, or factual scenario and raise the same or similar
            legal issues and seek the same or similar relief. Disagreements over
            the applicability of this Batch Arbitration process will be settled
            in a single, consolidated arbitration proceeding that includes all
            affected parties and is resolved by a single arbitrator subject to
            the requirements of this section. This Batch Arbitration provision
            shall in no way be interpreted as authorizing a class or collective
            arbitration or action of any kind, or any suit or arbitration
            involving joint or consolidated claims, under any circumstances
            other than those expressly set forth in this section.
          </p>

          <p>
            Entrant further agrees (x) under no circumstances will Entrant be
            permitted to obtain awards for, and Entrant hereby waives all rights
            to claim, punitive, incidental, consequential, or any other damages,
            other than for actual out-of-pocket expenses; (y) any and all
            claims, judgments, and award shall be limited to actual
            out-of-pocket costs incurred, excluding attorneys’ fees and court
            costs; (z) Entrant’s remedies are limited to a claim for money
            damages (if any). The prior limitation on damages is not intended to
            limit the Released Parties’ obligation (if any) to pay prevailing
            party costs or fees if recoverable pursuant to applicable law.
          </p>

          <ol start="10">
            <li>
              <strong>ENTRANTS’ PERSONAL INFORMATION:</strong> Information
              collected from participants is subject Sponsor’s Privacy Policy
              available at
              https://www.spinmaster.com/en-US/legal/privacy-policy/ .
            </li>
          </ol>

          <ol start="11">
            <li>
              <strong>WINNERS’ LIST/OFFICIAL RULES: </strong>A copy of these
              Official Rules is available online during the Sweepstakes Period
              by visiting the Sweepstakes Site. For information about who won,
              send an email to{" "}
              <a href="mailto:support@pawpatrolacademy.com">
                support@pawpatrolacademy.com
              </a>{" "}
              with the subject line “PAW Patrol Yelp For Help Sweepstakes.”
              Requests for winner information must be received no later than
              three (3) months after the end of the Sweepstakes Period.&nbsp;
            </li>
          </ol>

          <ol start="12">
            <li>
              <strong>SPONSOR: </strong>Spin Master Ltd., 225 King Street West
              Toronto, ON&nbsp; M5V 3M2&nbsp;
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
