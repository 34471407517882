const IOS_STORE_URL_STOREREDIR = "https://pawpatrolacademyappstore.onelink.me/8QSn/90ry5drq";
const ANDROID_STORE_URL_STOREREDIR = "https://pawpatrolacademyandroid.onelink.me/f1Ym/zh2v4ec4";


/*
* Go to a store, autodetecting based on user's device
* @appleUrl - app store url including protocol
* @androidUrl - google play store url include protocol
* @newTab - boolean. If yes, open link in new tab/window. Else use current window.
*/
export function gotostore(newTab = true, appleUrl = IOS_STORE_URL_STOREREDIR, androidUrl = ANDROID_STORE_URL_STOREREDIR){
    if (/Android/i.test(navigator.userAgent)) {
        if (newTab) {
            window.open(androidUrl, '_blank');
        } else {
            window.location.href=androidUrl;
        }
    } else {
        if (newTab) {
            window.open(appleUrl, '_blank');
        } else {
            window.location.href=appleUrl;
        }
    }
}
/*
* Go to a specific link
* @newTab = boolean. Whether it should open in a new tab/window. true == open in new tab. default: true
* @theUrl = The url to redirect to 
*/
export function gotostoreDirectly(newTab = true, theUrl = IOS_STORE_URL_STOREREDIR){
    if (newTab) {
        window.open(theUrl, '_blank');
    } else {
        window.location.href=theUrl;
    }
}

// export function gotostore() {
//     if (/Android/i.test(navigator.userAgent)) {
//         // window.location.href=ANDROID_STORE_URL_STOREREDIR;
//         window.open(ANDROID_STORE_URL_STOREREDIR, '_blank');
//     } else {
//         // window.location.href=IOS_STORE_URL_STOREREDIR;
//         window.open(IOS_STORE_URL_STOREREDIR, '_blank');
//     }
// }

// Fires an event that will eventually trigger Google Analytics event to fire, then redirect to store
export function handleDownloadClick(btnLocation, appleUrl = IOS_STORE_URL_STOREREDIR, androidUrl = ANDROID_STORE_URL_STOREREDIR) {
    if (btnLocation && btnLocation.length > 0) {
        // fire an event and use btnLocation as the location param
        window.dataLayer.push({
            "event":"download-btn-click",
            "buttonLocation":btnLocation,
        });
    } else {
        window.dataLayer.push({
            "event":"download-btn-click",
            "buttonLocation":"not specified",
        });
    }
    gotostore(true, appleUrl, androidUrl);
}