// NB: Cache will reset if user clicks enter on the address bar (even if you do not change url) or refreshes page

// Used as simple API results cache
// each kv pair represents a single post.  Keyname is the ID of the post in the DB.
export const postCache = {};

// API results cache for the 3 posts on the homepage
// Likely to contain a single kv pair that holds the data of all 3 posts. 
export const homePostsCache = {}

// Cache for news landing page
export const newsListCache = {}