import './Activities.scss';

import Slider from "react-slick";
// Import css files used by slick slider carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Carousel images:
import imgLearnVocab from "../../assets/images/activities/learn-vocab.webp";
import imgLetterWriting from "../../assets/images/activities/practice-letter-writing-with-rubble.webp";
import imgMakeMusic from "../../assets/images/activities/make-music-with-skye.webp";
import imgMasterNumbers from "../../assets/images/activities/master-umbers-with-marshall.webp";
import imgUncoverShapes from "../../assets/images/activities/uncover-shapes-with-rocky.webp";
import imgExploreArt from "../../assets/images/activities/art-with-zuma.webp";
import imgLookout from "../../assets/images/activities/ryder-at-the-lookout.webp" ;

import {forwardRef} from 'react';

// push event to help track carousel swipes
function handleSwipe(e) {
    window.dataLayer.push({'event': 'activity-carousel-swipe'});
}
// Ref is being passed in from parent (nb: it's not a prop, it doesn't have {}) and we use forwardRef (see the export line) to enable us to use
// ref from the parent in the first place. This will let use scroll to this component from a link in another component (Header)
// function Activities(ref) {
const Activities = forwardRef((props,ref) => {
// could also use: const Activities = forwardRef(function Activities(props, ref) {...});
// Or use in separate export statement at the end: export default forwardRef(Activities)

    var settings = {
        onSwipe: handleSwipe,
        dots: false,
        autoplay: {delay: 6000,pauseOnMouseEnter: true,},
        infinite: true,
        vertical: false,
        centerMode: true,
        mobileFirst: true,
        lazyLoad: 'ondemand',
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1, // must be 1 when center mode is on
        swipeToSlide: true, /* if enabled, swipe no longer smoothly scrolls multiple items like slidetoScroll, but tradeoff is do not see empty slide pop in either */
        initialSlide: 0,
        responsive: [
            {  
                breakpoint: 8000,
                settings: {
                    slidesToShow: 7, // cannot go higher than # of slides
                }
            },
            // The breakpoints are max. So breakpoint 3000 means do X when viewport is <= 3000px
            {  
                breakpoint: 2080,
                settings: {
                    slidesToShow: 6,
                }
            },
            {  
                breakpoint: 1780,
                settings: {
                    slidesToShow: 5,
                }
            },
            {  
                breakpoint: 1526,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1161,
                settings: {
                slidesToShow: 3,
                // slidesToScroll: 3,
                }
            },
            {
                breakpoint: 867,
                settings: {
                slidesToShow: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                slidesToShow: 3,
                initialSlide: 2
                }
            },
            {
                breakpoint: 739,
                settings: {
                slidesToShow: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 523, 
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                effect: 'cards',
                }
            }
        ]
      };
    
    return (

        <section className={`activities ${(props.pageMode && props.pageMode === "landing-page") ? "activities--landing-page" : ""}`} id="activities" ref={ref}>
            <h2 className="activities__title">
                So many inspiring, interactive activities in&nbsp;one&nbsp;app
            </h2>

        <div className="activities__carousel">
        <Slider {...settings}>
        
            <div className="activities__item">
                <img className="activities__item-image" src={imgLearnVocab}  alt="Learn vocabulary with Chase" />
                <p className="activities__item-desc">Learn vocabulary with Chase's ABCs</p>
            </div>
            <div className="activities__item">
                <img className="activities__item-image" src={imgLetterWriting}  alt="Practice letter writing with Chase"/>
                <p className="activities__item-desc">Practice letter-writing with Rubble</p>
            </div>
            <div className="activities__item">
                <img className="activities__item-image" src={imgMakeMusic} alt="Making music with Skye" />
                <p className="activities__item-desc">Make music with Skye</p>
            </div>
            <div className="activities__item">
                <img className="activities__item-image" src={imgMasterNumbers} alt="Mastering numbers with Marshall" />
                <p className="activities__item-desc">Master numbers with Marshall</p>
            </div>
            <div className="activities__item">
                <img className="activities__item-image" src={imgUncoverShapes} alt="Shapes with Rocky" />
                <p className="activities__item-desc">Uncover shapes with Rocky</p>
            </div>
            <div className="activities__item">
                <img className="activities__item-image" src={imgExploreArt}  alt="Art with Zuma" />
                <p className="activities__item-desc">Explore art with Zuma</p>
            </div>
            <div className="activities__item">
                <img className="activities__item-image" src={imgLookout} alt="Be on the lookout with Ryder" />
                <p className="activities__item-desc">Join Ryder at the Lookout</p>
            </div>
        
    </Slider>
    </div>
    </section>


    )
})

export default Activities;