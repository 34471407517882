
import MetaSEO from '../components/MetaSEO/MetaSEO';
import Header from '../components/Sweepstakes/Header/Header';
import Spotlight from '../components/Sweepstakes/Spotlight';
import Offer from '../components/Offer/Offer';
import Footer from '../components/Footer/Footer';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {Helmet} from 'react-helmet';

import '../styles/styles.scss';

export default function Sweepstakes({appleUrl, androidUrl}) {

    appleUrl = "https://pawpatrolacademyappstore.onelink.me/8QSn/4l43j083";
    androidUrl = "https://pawpatrolacademyandroid.onelink.me/f1Ym/xj2xxs1a";

    return (
        <>
        <GoogleReCaptchaProvider reCaptchaKey='6LenlEAqAAAAAPNVlO5qHprIuGXnJ_bSyeAXwk1D'>
        <Helmet>
            {/* Meta Pixel Script */}
            <script>
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1629406384288833');
                fbq('track', 'PageView');
            `}
            </script>

            {/* Noscript Pixel Fallback */}
            <noscript>
            {`
                <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=1629406384288833&ev=PageView&noscript=1" />
            `}
            </noscript>
        </Helmet>
        <MetaSEO 
            title="Yelp For Help Sweepstakes Contest | PAW Patrol Academy | Preschool Learning App | iOS & Android"
            canonical="https://www.pawpatrolacademy.com/"
            description="PAW Patrol Academy has a special message for stressed-out parents of preschoolers. In addition to trusted, age-appropriate learning content for your little ones, 
                    we're offering  $2,500 in gift card cash and prizes to help you achieve your goals. "
            keywords="Sweepstakes, contest, cash, free subscription, Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"    
            type="webapp"
            name="Originator Kids | Spin Master"
        />
            <div className="site-container">
                <Header appleUrl={appleUrl} androidUrl={androidUrl} pageMode="landing-page"/>
                <Spotlight appleUrl={appleUrl} androidUrl={androidUrl}/>
                <Offer appleUrl={appleUrl} androidUrl={androidUrl} />
                <div className="spacer-generic">&nbsp;</div>
                <Footer hideKidSafe={true} />
            </div>
        </GoogleReCaptchaProvider>
        </>

    )
}