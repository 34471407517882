import {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import printJS from 'print-js';

import MetaSEO from '../MetaSEO/MetaSEO';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import PrintableCategoryName from './PrintableCategoryName.jsx';
import PrintableListItem from './PrintableListItem.jsx';
import {postCache} from '../../utils/apicache.js';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    PinterestShareButton,
    PinterestIcon,
  } from "react-share";

import './PrintableDetails.scss';

const PrintableDetails = ({androidUrl, appleUrl}) => {
  const {printableId} = useParams();
  const [printableContent, setPrintableContent] = useState(null);
  const [morePrintables, setMorePrintables] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // setIsError(false);  
    
    // Prevents DOMPurify from stripping out target="_blank" in the article body when it sanitizes it.
    DOMPurify.setConfig({ ADD_ATTR: ['target'] }); 
  }, [])

  // Get the article
  useEffect(() => {
    window.scrollTo(0,0);
    let idNumber = parseInt(printableId);
    if (Number.isInteger(idNumber) && idNumber >= 0) {
      if (postCache[printableId]) {
        // console.log("found cache, so setting that as article");
        setPrintableContent(postCache[printableId]);
      } else {
        setIsLoading(true);
        axios.get(`https://pawwebsitearticleserver.azurewebsites.net/printables/${printableId}`)
        .then(response => {
          setIsLoading(false);
          // console.log("No cache, successful api call so setting that as new cache")
          setPrintableContent(response.data);
          postCache[printableId] = response.data;
        }, error => {
          setIsLoading(false);
          // console.log("Encountered error fetching article: ", error.message);
          setIsError(true);
        })
      }
      
      axios.get('https://pawwebsitearticleserver.azurewebsites.net/printableslist/')
      .then(response => {
        let filteredData = response.data.filter(item => parseInt(item.id) !== parseInt(printableId));
        console.log("here is content fo filteredData: ", filteredData);
        setMorePrintables(filteredData);
      }, error => {
        console.log("error fetching additional printables");
      })
    } else {
      console.log("Invalid article id format provided");
      setIsError(true);
    }
  }, [printableId])


  const pdfPath = "/images/printables/pdf/";
  const imagesPath = "/images/printables/images/";

  function printThis(pathToPDF) {
    if (/Android/i.test(navigator.userAgent) || /iPhone/i.test(navigator.userAgent)) {
        window.open(pathToPDF);
    } else {
        printJS(pathToPDF);
    }
  }

  if (printableContent) {
    return (
    <>
      <MetaSEO
        title={`${printableContent.title.rendered} Coloring Pages | PAW Patrol Academy | Preschool Learning App | iOS & Android`}
        canonical={`https://www.pawpatrolacademy.com/printables/${printableContent.id}`}
        description={`Coloring Pages from the Paw Patrol Academy App - ${printableContent.title.rendered}`}
        keywords="Coloring Pages, Coloring Sheet, Learning App, Learning games, Education App, ABC, Coloring app, Alphabet, Spelling, Academy,Preschool app,Playtime,Fun,Adventure,Shapes,Music,Dance,Kindergarten app,Chase,Skye, Rubble, The Lookout,Paw patrol"
        type="webapp"
        name="Originator Kids | Spin Master"
        isFAQ={false}
      />
      <article className="news-post">
        <section className="news-post__header">
            <p className="news-post__category"><PrintableCategoryName catId={printableContent["printable-category"]} /></p>
            <h1 className="news-post__title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(printableContent.title.rendered)}}></h1>

            <img className="news-post__splash-image" src={imagesPath+printableContent.acf.large_image_filename} alt="accompanying img for article" />
            <button className="download-button printable-details__print-btn" onClick={() => printThis(pdfPath+printableContent.acf.coloring_sheet_filename)}>Print Now</button>
        </section>
        <section className="news-post__share-box">
          <div className="news-post__share-buttons-box">
            <EmailShareButton
                url={`https://www.pawpatrolacademy.com/printables/`+printableContent.id+window.location.search}
                subject={`PAWsome printable from PAW Patrol Academy`}
                body={`I thought you might be interested in this PAWsome printable from PAW Patrol Academy: `}
                className="news-post__share-button"
                >
                <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton
                url={`https://www.pawpatrolacademy.com/printables/`+printableContent.id+window.location.search} // +pdfPath+printableContent.acf.coloring_sheet_filename}
                image={`https://www.pawpatrolacademy.com/`+imagesPath+printableContent.acf.preview_image_filename}
                title={`Paw Patrol Academy coloring sheet: `+printableContent.title.rendered}
                className="news-post__share-button">
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <PinterestShareButton
                url={`https://www.pawpatrolacademy.com/printables/`+printableContent.id}
                media={`https://www.pawpatrolacademy.com/`+imagesPath+printableContent.acf.preview_image_filename}
                description={`Paw Patrol Academy printable (coloring pages): `+printableContent.title.rendered}
                className="news-post__share-button">
                <PinterestIcon size={32} round />
            </PinterestShareButton>
          </div>

          <div className="printable-details__related">
            <div className="printable-details__divider"></div>
            <h3 className="printable-details__related-title">More Printables!</h3>
            <ul className="printables-list">    
              {(morePrintables) ?
                  morePrintables.map((result, index) => 
                    (index < 3) ? <PrintableListItem result={result} isSticky={false} appleUrl={appleUrl} androidUrl={androidUrl} key={index} compactMode={true} /> : null 
                  )
              : ""}
            </ul>
    
          </div>
          <Link className="news-post__all-articles-link" to={`/printables${window.location.search}`}>
          &#9668; See all printables
            {/* <img className="news-post__all-articles-link-icon" src="/images/go_up_icon.svg" alt="go up" />  */}
          </Link>

        </section>
      </article>
      </>
    )
  }

  // Error fetching article (either id is improper format or no matching article or network error)
  if (isError) {
    return (
      <ErrorMessage errorMessage="No matching printable found" />
    )
  }

  if (isLoading) {
    // REPLACE THIS WITH LOADING ANIMATION
    return (
      <div className="news-post__loading-container">
        <img className="news-post__loading-image" src="/images/loading.gif" alt="Content is loading" />
      </div>
    )
  }
}
export default PrintableDetails;