import {useState} from 'react';
import axios from 'axios';

import './Newsletter.scss';
// import formImage from "../../assets/images/misc/bottom-banner-paw.webp";
import loadingImage from '../../assets/images/misc/loading.gif';
import storeIconApple from "../../assets/images/logo/store_itunes.gif";
import storeIconGoogle from "../../assets/images/logo/store_google.gif";

import Countdown from '../Countdown/Countdown'; // counts down & redirects

/*
Expected props:
- androidUrl = full url to android store page including https protocol
- appleUrl = full url to app store including protocol
- formLoc (optional) = Set to upper if this is the upper form on the page. Ensures checkbox label on lower form does not affect upper form
- pageMode (optional) = Set to "landing-page" to ensure proper post-submission message for landing page & no redirect on mobile
*/
export default function NewsletterFormContent(props) {

    const apiUrl = "https://pawnewslettersignupserver.azurewebsites.net/"; // "http://localhost:8080/";
    // const apiUrl = "http://localhost:8080/";
    
    const [fieldEmail, setFieldEmail] = useState("");
    // const [fieldFirstName, setFieldFirstName] = useState("");
    // const [fieldLastName, setFieldLastName] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    // formSubmitted flag enables us to show the realtime error messages on blank fields only after submission & not before
    // Otherwise, our checks using the isValid() would eval to true even at page load & thus show error msg before user has typed anything
    // (we previously checked if input.length >0 + isValid, but this fails if user submits with empty field as error msg would still be hidden since len == 0)
    // (alt would be to disable submit button if not valid, but checkbox is the prob in that case as you'd never see the checkbox error msg)
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResult, setApiResult] = useState(""); // vals: success, error
    
    // Record whether is supported device as form success msg changes accordingly
    const isSupportedDevice = /iPhone|iPad|Android/i.test(navigator.userAgent);

    // Updates form field states as you type in them
    function handleChange(event) {
        if (event.target.name === "emailField") {
            setFieldEmail(event.target.value);
        // } else if (event.target.name === "firstnameField") {
        //     setFieldFirstName(event.target.value);
        // } else if (event.target.name === "lastnameField") {
        //     setFieldLastName(event.target.value);
        } else if (event.target.name === "optinCheckbox") {
            setIsChecked(!isChecked);
        }
    }

    // Validates email field
    function isEmailValid() {
        // const validRegex = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/;
        const validRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!fieldEmail.match(validRegex)) {
            return false;
        } else {
            return true;
        }
    }

    /***** Commenting out name check fxns since no longer need
     
    // Validates First Name field
    function isFirstNameValid() {
        if ((fieldFirstName.length < 2 && fieldFirstName.length < 51)) {
            return false;
        } else {
            return true;
        }
    }
    // Validates Last Name field
    function isLastNameValid() {
        if ((fieldLastName.length < 2 && fieldLastName.length < 51)) {
            return false;
        } else {
            return true;
        }
    }
    ****/

    // Handles form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        setApiResult(""); // clear out any prev msgs, eg network error msg
        setFormSubmitted(true);

        // if (isChecked && isEmailValid() && isFirstNameValid() && isLastNameValid()) {
        if (isChecked && isEmailValid()) {

            // display the loading icon in the form
            setIsLoading(true);

            // GET utm_source param value
            // 1st get the query params
            const searchParams = new URLSearchParams(window.location.search);
                // Could also use const [searchParams, setSearchParams] = useSearchParams() but all remaining code is same so why load another hook?
            // 2nd, convert all param names (not values) to lowercase since find value fxn searchparams.get() is case sensitive
            const newParams = new URLSearchParams();
            for (const [name, value] of searchParams) { 
                newParams.append(name.toLowerCase(), value);
            }
            const utmSourceValue = newParams.get('utm_source');
            
            // Create the data object that will post to endpoint
            const formData = { 
                email: fieldEmail,
                optInType: 'Single',
                emailType: 'Html',            
                REFERRER: utmSourceValue,
                SIGNUP_PAGE: window.location.href,
                // FIRSTNAME: fieldFirstName,
                // LASTNAME: fieldLastName,
            };

            // Now make the axios call
            axios.post(`${apiUrl}`, formData)
            .then(result => {  
                // SUCCESSFUL API SUBMISSION
                setIsLoading(false);
                setApiResult("success");
                // The success msg and redirect will happen in the return JSX automatically, triggered by these state changes


                
                /******************************************* */
                // INSERT STUFF TO FIRE GOOGLE ANALYTICS EVENT HERE
                // dataLayer only works with GTM loaded;
                /******************************************* */
                window.dataLayer.push({'event': 'lower-form-submitted'});


            }).catch(err => {
                // ERROR WITH API SUBMISSION
                // This will fire if network returns a non-2xx status
                // Generally if this fires, can assume that it's due to auth or network error, not form validation issue
                // console.error("Error:", error);
                console.log("Error posting item to api: ", err);
                setIsLoading(false);
                setApiResult("error");
                // apiResult = "error";
                console.log("error with api submission: ", err);
                
            });
            return;       
        }
    }

    return (
        <>        
               
            {/* <!-- Success message --> */}
            <div className={`form__success ${(apiResult === "success") ? "" : "form__success--hide"}`}>
                <p className={`form__success-copy ${(apiResult === "success" && props.pageMode !== "landing-page" && !isSupportedDevice) ? "" : "form__success--hide"}`}>You're on the list!<br />Choose a store to download now:</p>
                <p className={`form__success-copy ${(apiResult === "success" && props.pageMode !== "landing-page" && isSupportedDevice) ? "" : "form__success--hide"}`}>
                    You're on the list!<br />Redirecting you to the store<br />to download in&nbsp;
                    {(apiResult === "success" && isSupportedDevice) ? <Countdown timerValue="4" appleUrl={props.appleUrl} androidUrl={props.androidUrl}/> : ""} seconds...<br />
                    (or click below):
                </p>
                {(props.pageMode !== "landing-page") ?
                    <div className="form__success-icons-box">
                        <a href={props.appleUrl} className="form__success-link" target="_blank" rel="noreferrer"><img className="form__success-icon" src={storeIconApple} alt="Pre-Order app on Apple App store" /></a>
                        <a href={props.androidUrl} className="form__success-link" target="_blank" rel="noreferrer"><img className="form__success-icon" src={storeIconGoogle} alt="Pre-Order app on Google Play store" /></a>
                    </div>                
                : <p className="form__success-copy"><strong>Thank you for subscribing!<br />For access to your extended free trial code, check your email.</strong></p> }
            </div>
            {/* <!-- Error message --> */}
            <div className={`form__error ${(apiResult === "error") ? "" : "form__error--hide"}`}>
                <p className="form__error-copy">Sorry, there was an error<br />submitting your request.<br />Please try again later!</p>
            </div>
            {/* <!-- Inline form --> */}
            <form id="form-inline__bottom" className={`form-inline ${(apiResult === "success")? "form__success--hide" : ""}`} onSubmit={handleSubmit} noValidate>
                <p className={`form-inline__warning ${(!isEmailValid() && (fieldEmail.length > 0 || formSubmitted)) ? "" : "form-inline__warning-hide"}`}>
                    Please add a valid email address</p>
                <input type="email" id="emailField" name="emailField" className="form-inline__input" placeholder="Parent's Email Address" value={fieldEmail} onChange={handleChange} />
                {/* <p className={`form-inline__warning ${(!isFirstNameValid() && (fieldFirstName.length > 0 || formSubmitted)) ? "" : "form-inline__warning-hide"}`}>Please add parent's first name</p>
                <input type="text" id="firstnameField" name="firstnameField" className="form-inline__input" placeholder="Parent's First Name" value={fieldFirstName} onChange={handleChange} />
                <p className={`form-inline__warning ${(!isLastNameValid() && (fieldLastName.length > 0 || formSubmitted)) ? "" : "form-inline__warning-hide"}`}>Please add parent's last name</p>
                <input type="text" id="lastnameField" name="lastnameField" className="form-inline__input" placeholder="Parent's Last Name" value={fieldLastName} onChange={handleChange} /> */}
                <p className={`form-inline__warning ${(!isChecked && formSubmitted) ? "" : "form-inline__warning-hide"}`}>Please provide your consent by ticking the box</p>
                <div className="form-inline__checkbox-container">
                    <label htmlFor={(props.formLoc === "upper") ? `optinCheckboxTop` : `optinCheckboxBottom`} className="form-inline__checkbox-label">
                        <input type="checkbox" id={(props.formLoc === "upper") ? `optinCheckboxTop` : `optinCheckboxBottom`} name="optinCheckbox" className="form-inline__checkbox" checked={isChecked} onChange={handleChange} />
                        <span className="form-inline__checkbox-label-copy">Yes! I want to receive PAW Patrol Academy™ promotional emails from Spin&nbsp;Master</span>
                    </label>
                </div>
                <button type="submit" className="form-inline__button">
                    <img className={`form-inline__button-loading ${(isLoading) ? "" : "form-inline__hide-misc"}`} src={loadingImage} loading="lazy" alt="loading progress" />
                    {(!isLoading) ? (<span className="form-inline__button-copy">Join Us</span>) : ""}
                </button>
            </form>
        </>
                
    )
}