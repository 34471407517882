import './Spotlight.scss';

import chaseImage from '../../assets/images/spotlight/chase.webp';
import spotlightBurst from '../../assets/images/spotlight/spotlightbkgd.webp';
import {handleDownloadClick} from '../../utils/gotostore.js';

export default function Spotlight({appleUrl, androidUrl}) {

    return (
        <section className="spotlight">
            <div className="spotlight__image-box">
                <img className="spotlight__image-bg" src={spotlightBurst} alt="Background starburst" />
                <img className="spotlight__image-fg" loading="lazy" src={chaseImage} alt="Chase from Paw Patrol Academy" />
            </div>
            <div className="spotlight__content">
                <h3 className="spotlight__subtitle">Featured Activity:</h3>
                <h2 className="spotlight__title">Heroes in Training</h2>
                <p className="spotlight__copy">
                    Age-appropriate lessons focused on self-care, feelings and life skills go beyond ABCs and 123s to nurture your preschooler's emotional health 
                    and social skills.  Your child's confidence and maturity will reach new heights when lessons come directly from the PAW Patrol.</p>
                 <button className="download-button" onClick={() => handleDownloadClick("spotlight", appleUrl, androidUrl)}>Try It Now</button>
            </div>
        </section>
    )
}