// before hardcode
import {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';

import './NewsArticlePreview.scss';
import MetaSEO from '../MetaSEO/MetaSEO.jsx';
import ErrorMessage from '../ErrorMessage/ErrorMessage.jsx';
import CategoryName from '../CategoryName/CategoryName.jsx';
import GetDate from '../GetDate/GetDate.jsx';
import {postCache} from '../../utils/apicache.js';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const NewsArticle = () => {

  const {articleId} = useParams();
  const [articleContent, setArticleContent] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    // setIsError(false);  
    window.scrollTo(0,0);
      
      // Prevents DOMPurify from stripping out target="_blank" in the article body when it sanitizes it.
      DOMPurify.setConfig({ ADD_ATTR: ['target'] }); 
  }, [])

  // Get the article
  useEffect(() => {
    let idNumber = parseInt(articleId);
    if (Number.isInteger(idNumber) && idNumber >= 0) {
      if (postCache[articleId]) {
        // console.log("found cache, so setting that as article");
        setArticleContent(postCache[articleId]);
      } else {
        setIsLoading(true);
        axios.get(`https://pawwebsitearticleserver.azurewebsites.net/postsfuture/${articleId}`)
        // axios.get(`http://localhost:8000/posts/${articleId}`)
        .then(response => {
          setIsLoading(false);
          // console.log("No cache, successful api call so setting that as new cache")
          setArticleContent(response.data);
          postCache[articleId] = response.data;
        }, error => {
          setIsLoading(false);
          // console.log("Encountered error fetching article: ", error.message);
          setIsError(true);
        })
      }
      
    } else {
      console.log("Invalid article id format provided");
      setIsError(true);
    }
  }, [articleId])



  if (articleContent) {

    // NOTE: We use DangerouslySetInnerHTML (along with DOMpurify) as CMS feed converts chars like "&" into char entity codes which react will not convert back to orig char unless use dangerously.
    return (
    <>
      <MetaSEO
        title={`PREVIEW: ${articleContent.title.rendered} | News | PAW Patrol Academy | Preschool Learning App | iOS & Android`}
        isFAQ={false}
        noindex={true}
      />
      <article className="news-post">
        <section className="news-post__header">
            <p className="news-post__category"><CategoryName catId={articleContent.categories[0]} /></p>
            <h1 className="news-post__title" dangerouslySetInnerHTML={{__html: "(PREVIEW) "+DOMPurify.sanitize(articleContent.title.rendered)}}></h1>
            <p className="news-post__date"><GetDate theDate={articleContent.acf.displaydate} /></p>
            <img className="news-post__splash-image" src={articleContent.acf.splashimageurl} alt="accompanying img for article" />
        </section>
        <section className="news-post__body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(articleContent.content.rendered)}}>
        </section>
        <section className="news-post__share-box">
          <div className="news-post__share-buttons-box">
            <EmailShareButton
              url={window.location.href}
              subject={`${articleContent.title.rendered} article`}
              body="Check out this article from PAW Patrol Academy: "
              className="news-post__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton
              url={window.location.href}
              title={articleContent.title.rendered}
              className="news-post__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <WhatsappShareButton
              url={window.location.href}
              title={articleContent.title.rendered}
              separator=":: "
              className="news-post__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <RedditShareButton
              url={window.location.href}
              title={articleContent.title.rendered}
              windowWidth={660}
              windowHeight={460}
              className="news-post__share-button"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
          </div>
          <Link className="news-post__all-articles-link" to={`/news${window.location.search}`}>
          &#9668; See all articles
            {/* <img className="news-post__all-articles-link-icon" src="/images/go_up_icon.svg" alt="go up" />  */}
          </Link>
        </section>
      </article>
      </>
    )
  }

  // Error fetching article (either id is improper format or no matching article or network error)
  if (isError) {
    return (
      <ErrorMessage errorMessage="No matching article found" />
    )
  }

  if (isLoading) {
    // REPLACE THIS WITH LOADING ANIMATION
    return (
      <div className="news-post__loading-container">
        <img className="news-post__loading-image" src="/images/loading.gif" alt="Content is loading" />
      </div>
    )
  }
}
export default NewsArticle;