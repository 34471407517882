import './Features.scss';

// Features icons
import featuresTrio from '../../assets/images/features/01-marshall-zuma-rocky.webp';
import featuresDuo from '../../assets/images/features/02-rubble-chase.webp';
import featuresRyder from '../../assets/images/features/03-ryder.webp';
import featuresSkye from '../../assets/images/features/04-skye-fly-fast.webp';

import {useRef, useLayoutEffect} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

// required to use the CustomEase plugin from gsap
gsap.registerPlugin(ScrollTrigger);

export default function Features() {

    const featuresArea = useRef();

    useLayoutEffect( () => {

        let featuresCtx = gsap.context(() => {
            // gsap.from(".features__item--aplus", {scrollTrigger: {trigger: ".features__item--aplus", markers: false}, ease: "bounce.out", x: window.innerWidth, duration: 2});
            // gsap.from(".features__item--challenges", {scrollTrigger: {trigger: ".features__item--challenges"}, ease: "bounce.out", x: window.innerWidth, duration: 2, delay: 0.2});
            // gsap.from(".features__item--free-time", {scrollTrigger: {trigger: ".features__item--free-time"}, ease: "bounce.out", x: window.innerWidth, duration: 2, delay: 0.3});
            // gsap.from(".features__item--offer", {scrollTrigger: {trigger: ".features__item--offer"}, ease: "bounce.out", x: window.innerWidth, duration: 2, delay: 0.4});
            gsap.from(".features__item", {scrollTrigger: {trigger: ".features__item--aplus", start: "bottom bottom", markers: false}, x: window.innerWidth, ease:"elastic.out(1, 0.5)", stagger: 0.3, duration: 2, delay: 0.1});
        }, featuresArea)
        return () => {
            featuresCtx.revert();
        }
    }, []);

    return (
        <section className="features" ref={featuresArea}>
            <h2 className="features__title">Why parents love PAW&nbsp;Patrol&nbsp;Academy</h2>
            <div className="features__item features__item--aplus">
                <div className="features__box-bg">
                    <div className="features__box-fg">
                        <div className="features__copy-box features__copy-box--aplus">
                            <h3 className="features__copy-title">A+ content</h3>
                            <p className="features__copy">featuring your child's best&nbsp;friends</p>
                        </div>
                    </div>
                </div>
                <img src={featuresTrio} loading="lazy" className="features__image features__image--aplus" alt="Paw Patrol pups trio" />
            </div>
            <div className="features__item features__item--challenges">
                <div className="features__box-bg">
                    <div className="features__box-fg">
                        <div className="features__copy-box features__copy-box--challenges">
                            <h3 className="features__copy-title">Rewarding challenges</h3>
                            <p className="features__copy">for growing minds</p>
                        </div>
                    </div>
                </div>
                <img src={featuresDuo} loading="lazy" className="features__image features__image--challenges" alt="Paw Patrol pups duo high five" />
            </div>
            <div className="features__item features__item--free-time">
                <div className="features__box-bg">
                    <div className="features__box-fg">
                        <div className="features__copy-box features__copy-box--free-time">
                            <h3 className="features__copy-title">Well-earned free time</h3>
                            <p className="features__copy">for parents as kids learn</p>
                        </div>
                    </div>
                </div>
                <img src={featuresRyder} loading="lazy" className="features__image features__image--free-time" alt="Paw Patrol atv vehicle rider" />
            </div>
            <div className="features__item features__item--offer">
                <div className="features__box-bg">
                    <div className="features__box-fg">
                        <div className="features__copy-box features__copy-box--offer">
                            <h3 className="features__copy-title">Free trial offer</h3>
                            <p className="features__copy">Sign up today!</p>
                        </div>
                    </div>
                </div>
                <img src={featuresSkye} loading="lazy" className="features__image features__image--offer" alt="Paw Patrol flying puppy" />
            </div>
        </section>
    )
};
