import './Footer.scss';
import {Link} from 'react-router-dom';
import socialFB from "../../assets/images/social/icon-facebook.svg";
import socialIG from "../../assets/images/social/icon-instagram.svg";
import socialYT from "../../assets/images/social/icon-youtube.svg";
import originatorLogo from "../../assets/images/logo/originator-white.webp";
import spinMasterLogo from "../../assets/images/logo/Spin_Master_logo.gif";


export default function Footer({pageType, hideKidSafe}) {

    return (
    <footer className="footer">
        <div className="footer__social">
            <h3 className="footer__social-title">Follow us on social</h3>
            <div className="footer__social-box">
                <a href="https://www.facebook.com/profile.php?id=100090709899105" target="_blank" rel="noreferrer" className="footer__social-link">
                    <img src={socialFB} loading="lazy" className="footer__social-icon" alt="facebook icon" />
                </a>
                <a href="https://www.instagram.com/pawpatrolacademy/" target="_blank" rel="noreferrer" className="footer__social-link">
                    <img src={socialIG} loading="lazy" className="footer__social-icon" alt="instagram icon" />
                </a>
                <a href="https://www.youtube.com/channel/UC1-svEIqpWoIG6vw8MLdqsQ" target="_blank" rel="noreferrer" className="footer__social-link">
                    <img src={socialYT} loading="lazy" className="footer__social-icon" alt="youtube icon" />
                </a>
            </div>
        </div>
        <div className="footer__logos-legal-box">
            
            <div className="footer__logos">
                <a href="https://www.originatorkids.com/" target="_blank" rel="noreferrer"><img loading="lazy" className="footer__logos-image footer__logos-image--originator" src={originatorLogo} alt="Originator logo"/></a>
                <a href="https://www.spinmaster.com/" target="_blank" rel="noreferrer"><img loading="lazy" className="footer__logos-image" src={spinMasterLogo} alt="Spin Master logo"/></a>
            </div>
            <div className="footer__links">
                {
                    (pageType === "FAQ") 
                    ? <button className="footer__links-item footer__links-item-button" onClick={() => window.scrollTo(0,0)}>FAQ</button>
                    : <Link to={`/faq/${window.location.search}`} className="footer__links-item">FAQ</Link>
                }
                <div className="footer__links-divider">&bull;</div>
                <a href="https://www.spinmaster.com/en-US/legal/privacy-policy" target="_blank" rel="noreferrer" className="footer__links-item">Privacy Policy</a>
                <div className="footer__links-divider">&bull;</div>
                <a href="https://www.spinmaster.com/en-US/legal/terms-of-use/" target="_blank" rel="noreferrer" className="footer__links-item">Terms of Use</a>
                <div className="footer__links-divider">&bull;</div>
                <a href="mailto:support@pawpatrolacademy.com" target="_blank" rel="noreferrer" className="footer__links-item">Customer Service</a>
            </div>
            <p className="footer__legal">PAW Patrol Academy - &trade; & &copy; Spin Master Ltd. All rights reserved.</p>
            {(hideKidSafe === true) ? null
            : <a className="footer__kidsafe" href="https://www.kidsafeseal.com/certifiedproducts/pawpatrolacademy_app.html" target="_blank" rel="noreferrer">
                <img border="0" loading="lazy" alt="PAW Patrol Academy is certified by the kidSAFE Seal Program." src="https://www.kidsafeseal.com/sealimage/33583706916014976/pawpatrolacademy_app_medium_darktm.png" />
            </a>
            }
        </div>
    </footer>
    )
}