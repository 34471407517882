import DOMPurify from 'dompurify';
import printJS from 'print-js';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import PrintableCategoryName from './PrintableCategoryName';
import './PrintableListItem.scss'
import yellowShareIcon from '../../assets/images/social/share-yellow.webp';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    PinterestShareButton,
    PinterestIcon
  } from "react-share";
export default function PrintableListItem({result, compactMode}) {


    const [showShareIcons, setShowShareIcons] = useState(false);

    const pdfPath = "/images/printables/pdf/";
    const imagesPath = "/images/printables/images/";
    
    function printThis(pathToPDF) {
        if (/Android/i.test(navigator.userAgent) || /iPhone/i.test(navigator.userAgent) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)) {
            // mac & touchpoints test is to detect iPad reliably
            window.open(pathToPDF);
        } else {
            printJS(pathToPDF);
        }

    }

    return (
        <>
        {(result)
            ? <li className="printables-list__item">
                <Link className="printables-list__item-link" to={`/printables/${result.id}${window.location.search}`}>
                    <img className="printables-list__item-thumb" src={imagesPath+result.acf.preview_image_filename} alt="printable's thumbnail" loading="lazy" />
                </Link>
                <div className="printables-list__item-text-box">
                    <p className="printables-list__item-category">
                        <Link className="printables-list__item-unstyled-link" to={`/printables/${result.id}${window.location.search}`}>
                        <PrintableCategoryName catId={result["printable-category"]} /> 
                        </Link>
                    </p>
                    <Link className="printables-list__item-unstyled-link" to={`/printables/${result.id}${window.location.search}`}><h3 className="printables-list__item-title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(result.title.rendered)}}></h3></Link>
                    {/* <h3 className="printables-list__item-title">{result.title.rendered}</h3> */}

                    {(compactMode === true) 
                    ? null
                    : 
                    <div className="printables-list__item-print-share-box">
                        <button className="printables-list__item-print-btn" onClick={() => printThis(pdfPath+result.acf.coloring_sheet_filename)}>Print Now</button>
                        <div className="printables-list__item-share-container">
                            <button className={`printables-list__item-share-link ${(showShareIcons) ? "printables-list__item-share-link--active" : null}`} onClick={()=>setShowShareIcons(!showShareIcons)}><img className="printables-list__item-share-landing-icon" src={yellowShareIcon} alt="Share this" />Share</button>
                            <div className={`printables-list__item-share-icons-box  ${(showShareIcons) ? "printables-list__item-share-icons-box--active" : null}`}>
                                <EmailShareButton
                                    url={`https://www.pawpatrolacademy.com/printables/`+result.id+window.location.search}
                                    subject={`PAWsome printable from PAW Patrol Academy`}
                                    body={`I thought you might be interested in this PAWsome printable from PAW Patrol Academy: `}
                                    className="news-post__share-button"
                                    >
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                                <FacebookShareButton
                                    url={`https://www.pawpatrolacademy.com/printables/`+result.id+window.location.search} // +pdfPath+result.acf.coloring_sheet_filename}
                                    image={`https://www.pawpatrolacademy.com/`+imagesPath+result.acf.preview_image_filename}
                                    title={`Paw Patrol Academy coloring sheet: `+result.title.rendered}
                                    className="news-post__share-button">
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <PinterestShareButton
                                    url={`https://www.pawpatrolacademy.com/printables/`+result.id}
                                    media={`https://www.pawpatrolacademy.com/`+imagesPath+result.acf.preview_image_filename}
                                    description={`Paw Patrol Academy printable (coloring page): `+result.title.rendered}
                                    className="news-post__share-button">
                                    <PinterestIcon size={32} round />
                                </PinterestShareButton>
                                {/* <button className="printables-list__item-share-close" onClick={()=>setShowShareIcons(false)}>X</button> */}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </li>
            : null
        }
        </>
    )

}