import './Offer.scss';

import storeGoogle from '../../assets/images/logo/store_google.gif';
import storeApple from '../../assets/images/logo/store_itunes.gif';

import {useRef, useLayoutEffect} from 'react';
import {handleDownloadClick} from '../../utils/gotostore.js';

// animation pkg for triggered animation 
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

// required to use the CustomEase plugin from gsap
// gsap.registerPlugin(CustomEase, ScrollTrigger);
gsap.registerPlugin(ScrollTrigger);

export default function Offer({appleUrl, androidUrl}) {

    const burstRef = useRef();

    useLayoutEffect( () => {
        let ctxOfferBurst = gsap.context(() => {

            // Nice fade in from bottom, then each fades out from bottom
            // gsap.from(".offer__bg", {scrollTrigger: {trigger: ".offer__bg", markers: false}, scale:0, xPercent: -50, ease:"bounce.out", duration: 1.7});            
            gsap.fromTo(".offer__bg", 
            {scale:0, xPercent: -50, yPercent: -50},
            {scrollTrigger: {trigger: ".offer__bg", markers: false, start: "center bottom"},scale:1, xPercent: -50, yPercent: -50, ease:"bounce.out", duration: 3},
            );
            gsap.from(".offer__bg", 
            {scrollTrigger: {trigger: ".offer__bg", markers: false, start: "center bottom"},opacity:0, duration: 1},
            );
            
        }, burstRef);

        return () => {
            ctxOfferBurst.revert();
        }
    }, [])

    return (
        <section className="offer" ref={burstRef}>
            <div className="offer__bg">&nbsp;</div>
            <div className="offer__content">
                <h3 className="offer__subtitle">
                    Subscribe NOW to
                </h3>
                <h2 className="offer__title">Get your free&nbsp;trial</h2>
                <h3 className="offer__subtitle">
                when signed up for monthly/yearly&nbsp;plan
                </h3>
                <button className="download-button offer__button" onClick={() => handleDownloadClick("trialoffer", appleUrl, androidUrl)}>Yes, I want my free&nbsp;trial</button>
                <div className="offer__store-box">
                    <a href={appleUrl} className="offer__store-link" target="_blank" rel="noreferrer"><img className="offer__store-icon" src={storeApple} alt="Get Paw Patrol Academy at Apple iOS app store" /></a>
                    <a href={androidUrl} className="offer__store-link" target="_blank" rel="noreferrer"><img className="offer__store-icon" src={storeGoogle} alt="Get Paw Patrol Academy app at Google Play store" /></a>
                </div>
            </div>

        </section>
    )
}